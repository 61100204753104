import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class HeaderHttpInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('token') as string;
    const culture = localStorage.getItem('culture') as string;

    let newHeaders = req.headers;
    if (token != null) {
      newHeaders = newHeaders.append('Authorization', 'Bearer ' + token);
    }

    if (culture != null) {
      newHeaders = newHeaders.append('Culture', culture);
    }

    const authReq = req.clone({headers: newHeaders});
    return next.handle(authReq);
  }

}
