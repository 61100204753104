import { Component, OnInit } from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {User} from '../../model/user';
import {Router} from '@angular/router';
import {UserService} from '../../service/user.service';
import * as moment from 'moment';
import {TranslateService} from "@ngx-translate/core";

declare var $: any;

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent extends BaseComponent implements OnInit {
  modelUser: User = new User();
  myRecaptcha: boolean = false;

  promoEmailConfirm = '';
  promoPasswordConfirm = '';

  constructor(public router: Router,
              public userService: UserService,
              public translate: TranslateService,) {
    super(router, translate)
  }

  ngOnInit(): void {
    console.log('init');
    console.log(this.modelUser);
  }

  onScriptLoad() {
    console.log('Google reCAPTCHA loaded and is ready for use!')
  }

  onScriptError() {
    console.log('Something went long when loading the Google reCAPTCHA')
  }

  onChangeGender(m: string) {
    this.modelUser.sex = m;
  }

  onSave() {
    const arrValidateFields = [
      {value: this.modelUser.cpf, text: 'CPF*, <br />'},
      {value: this.modelUser.name, text: 'Nome*, <br />'},
      {value: this.modelUser.email, text: 'E-mail*, <br />'},
      {value: this.promoEmailConfirm, text: 'Confirmar E-mail*, <br />'},
      {value: this.modelUser.sex, text: 'Sexo*, <br />'},
      {value: this.modelUser.birthdate, text: 'Data de nascimento*, <br />'},
      {value: this.modelUser.phone, text: 'Telefone*, <br />'},
      {value: this.modelUser.cellphone, text: 'Celular*, <br />'},
      {value: this.modelUser.zipcode, text: 'CEP*, <br />'},
      {value: this.modelUser.address, text: 'Endereço*, <br />'},
      {value: this.modelUser.number, text: 'Número*, <br />'},
      {value: this.modelUser.state_id, text: 'Estado*, <br />'},
      {value: this.modelUser.city_id, text: 'Cidade*, <br />'},
      {value: this.modelUser.district, text: 'Bairro*, <br />'},
      {value: this.modelUser.password, text: 'Senha*, <br />'},
      {value: this.modelUser.passwordConfirm, text: 'Confirmar Senha*, <br />'},
    ];
    const stringError = this.validateField(arrValidateFields);
    if (!super.isNullOrUndefined(stringError)) {
      this.showMessage(this.translate.instant('attention'),  this.translate.instant('required'), 'warning');
      return;
    }
    if (!this.isEMailValid(this.modelUser.email)) { return; }
    if (this.modelUser.email !== this.promoEmailConfirm) {
      super.showMessage(this.translate.instant('attention'),  this.translate.instant('verify'), 'warning');
      return;
    }
    if (this.modelUser.password !== this.modelUser.passwordConfirm) {
      super.showMessage(this.translate.instant('attention'), this.translate.instant('password'), 'warning');
      return;
    }
    if (!this.isCPFValid(this.modelUser.cpf)) { return; }
    if (!super.dateOfBirthDateValidate(this.modelUser.birthdate)) { return; } else {
      console.log(this.modelUser.birthdate);
      this.modelUser.birthdate = moment(this.modelUser.birthdate, 'DD/MM/YYYY').toDate();
      console.log(this.modelUser.birthdate);
    }

    if (!this.myRecaptcha) {
      super.showMessage(this.translate.instant('attention'), this.translate.instant('validate'), 'warning');
      return;
    }

    if (!this.modelUser.is_accept) {
      super.showMessage(this.translate.instant('attention'), this.translate.instant('regulament'), 'warning');
      return;
    }

    console.log(this.modelUser);
    this.showMessage(this.translate.instant('congrats'), this.translate.instant('cadastro'), 'success');
  }

  handleReset() {
    console.log('handleReset');
  }

  handleReady() {
    console.log('handleReady');
  }

  handleLoad() {
    console.log('handleLoad');
  }

  handleSuccess($event: string) {

  }

  onZipCodeAutoComplete(event: KeyboardEvent) {
    const a = $(event.target).val();
    if (a.length > 8) {
      this.userService.getCep(a.replace('-','')).subscribe({
        next: data => {
          const x: any = data;
          this.modelUser.address = x.logradouro;
          this.modelUser.district = x.bairro;
          this.modelUser.city_id = x.localidade;
          this.modelUser.state_id = x.uf;
          // $('#promoNumber').focus();
        }
      });
    }
  }

  onClearModel(event: FocusEvent, str: string) {
    setTimeout(() => {
      const a = $(event.target).val();
      if (super.isNullOrUndefined(a)) {
        this.modelUser[str] = '';
      }
    });
  }
}
