import {Injectable} from '@angular/core';
import {ServiceBase} from '../base/service.base';
import {HttpClient} from '@angular/common/http';
import {User} from "../../generated/graphql";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class UserService extends ServiceBase {
  isAuthenticated = false;

  private userDataSource = new BehaviorSubject<User>(new User());
  user = this.userDataSource.asObservable();

  constructor(http: HttpClient) {
    super(http);
  }

  getBank() {
    const url = `${this.BASE_URL}/bank/list-bank`;
    return this.http.get(url);
  }

  updateUser(user: User) {
    this.userDataSource.next(user);
  }

  getCep(i) {
    const url = `https://viacep.com.br/ws/${i}/json`;
    return this.http.get(url);
  }

  getStatusAuthenticated() {
    const token = localStorage.getItem('token') as string;
    if (token != null) {
      this.isAuthenticated = true;
    } else {
      this.isAuthenticated = false;
    }
    return this.isAuthenticated;
  }


}
