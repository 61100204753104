import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Apollo, QueryRef} from 'apollo-angular';
import {
  CreateIdeaCommentGQL,
  GetChallengeGQL,
  GetIdeaGQL,
  GetIdeaQuery,
  GetNewsGQL,
  GetNewsQuery, GetNewsSearchGQL, GetNewsSearchQuery
} from '../../../generated/graphql';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-clic-news',
  templateUrl: './clic-news.component.html',
  styleUrls: ['./clic-news.component.scss']
})
export class ClicNewsComponent extends BaseComponent implements OnInit {
  paramId = null;
  news: any; // Observable<Query['getChallenges']>;
  newsQuery: QueryRef<GetNewsSearchQuery>;
  filter: any = {keyword: '', page: 1};
  keyword = '';
  status = 'published';
  labels: any = {
    previousLabel: '',
    nextLabel: '',
    screenReaderPaginationLabel: 'Pagination',
    screenReaderPageLabel: 'page',
    screenReaderCurrentLabel: `You're on page`
  };


  constructor(public router: Router,
              public route2: ActivatedRoute,
              private getNewsSearchGQL: GetNewsSearchGQL,
              public translate: TranslateService) {
    super(router,translate);
  }



  ngOnInit(): void {
    this.newsQuery = this.getNewsSearchGQL.watch({
      keyword: this.keyword,
      language: this.translate.currentLang === 'es' ? 'es-ES' : 'pt-BR',
      status: 'published'
    });
    this.newsQuery.valueChanges.subscribe(({data}) => {
      this.news = data.getNewsSearch;
    });

    this.route2.params.subscribe(url => {
      if (!this.isNullOrUndefined(url) && !this.isNullOrUndefined(url.id)) {
        this.paramId = url.id;
        this.keyword = this.paramId;
        this.newsQuery.refetch({keyword: this.paramId});
      }
    });
  }

  onKey(value): void {
    this.newsQuery.refetch({
      keyword: value.length < 3 ? '' : value, language: this.translate.currentLang === 'es' ? 'es-ES' : 'pt-BR', status: 'published'
    });
  }

  pageChanged(event, value) {
    this.filter.page = event;
    this.onKey(value);
  }
}
