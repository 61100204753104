import {AfterContentInit, Component, OnInit} from '@angular/core';
import {BaseComponent} from "../../base/base.component";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";

declare var $: any;

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent extends BaseComponent implements OnInit, AfterContentInit {

  constructor(public router: Router,
              public translate: TranslateService) {
    super(router, translate)
  }

  ngOnInit(): void {
    localStorage.clear();
  }

  ngAfterContentInit(): void {
    super.toggleLoader(false);
  }

}
