<div class="modal--idea">
  <div class="modal-content">
    <ng-container [ngSwitch]="page">
      <ng-container *ngSwitchCase="'challenge'" [ngTemplateOutlet]="challenge"></ng-container>
      <ng-container *ngSwitchCase="'description'" [ngTemplateOutlet]="description"></ng-container>
      <!--PROBLEMAS NAS MASK USANDO NG-CONTAINER-->
      <div class="modal-body" [ngClass]="{'d-none':page !== 'author'}">
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-6 over-gradient">
              <div class="modal--idea__question">
                <div class="modal--idea__wrapper">
                  <ng-container [ngTemplateOutlet]="language"></ng-container>
                  <h3 class="modal--idea__title">{{"newIdea" | translate}}</h3>
                  <h4 class="modal--idea__step">03</h4>
                  <h5 class="modal--idea__section">{{"author.asAuthor" | translate}}</h5>
                </div>
                <div class="modal--idea__col">
                  <button type="button" class=" modal--idea__back" (click)="onBackModal('description')">
                    <svg-icon class="svg" src="assets/images/back.svg"></svg-icon>
                    <span>{{"back" | translate}}</span>
                  </button>
                  <div class="d-flex align-items-center">
                    <div class="modal--idea__dots mx-2">
                      <div class="modal--idea__dots-item dots-back"></div>
                      <div class="modal--idea__dots-item dots-back"></div>
                      <div class="modal--idea__dots-item dots-now"></div>
                      <div class="modal--idea__dots-item"></div>
                      <div class="modal--idea__dots-item"></div>
                      <div class="modal--idea__dots-item"></div>
                      <div class="modal--idea__dots-item"></div>
                    </div>
                    <svg-icon class="svg" src="assets/images/logo-icon-white.svg"></svg-icon>
                  </div>
                </div>

              </div>
            </div>
            <div class="col-12 col-md-6 over-custom">
              <div class="modal--idea_header">
                <div class="bg-white" style="height: 90px;">
                  <svg-icon src="assets/images/logo.svg"></svg-icon>
                </div>
              </div>
              <div class="modal--idea__form">

                <form class="modal--idea__wrapper mx-auto">
                  <div class="input-material">
                    <input id="nameAuthor" name="name" class="form-control" type="text"
                           maxlength="250"
                           [(ngModel)]="model.authorName" required/>
                    <label for="nameAuthor">{{"author.name" | translate}}</label>
                  </div>
                  <div class="input-material">
                    <input id="cargo" name="text" class="form-control" type="text"
                           maxlength="250"
                           [(ngModel)]="model.role" required/>
                    <label for="cargo">{{"author.position" | translate}}</label>
                  </div>
                  <div class="d-flex flex-column flex-md-row">
                    <div class="flex-grow-1">
                      <div class="input-material">
                        <input id="re" name="text" class="form-control" inputmode="numeric" appOnlyNumber type="text"
                               [(ngModel)]="model.re" maxlength="6" required/>
                        <label for="re">RE</label>
                      </div>
                    </div>
                    <div style="width: 1em;height: 0">&nbsp;</div>
                    <div class="flex-grow-1">
                      <div class="input-material flex-grow-1">
                        <input id="work" name="text" class="form-control" type="text"
                               maxlength="250"
                               [(ngModel)]="model.turn" required/>
                        <label for="work">{{"author.period" | translate}}</label>
                      </div>
                    </div>

                  </div>
                  <div class="input-material">
                    <input id="manager" name="text" class="form-control" type="text"
                           maxlength="250"
                           [(ngModel)]="model.managerName" required/>
                    <label for="manager">{{"author.superior" | translate}}</label>
                  </div>
                  <div class="input-material">
                    <input id="phone1" name="text" class="form-control" type="text"
                           [(ngModel)]="model.phone" autocomplete="off" inputmode="numeric"
                           [mask]="'+00 (00) 00000- 0000 ||+00 (00) 0000-0000'"
                           required/>
                    <label for="phone1">{{'phone'|translate}}</label>
                  </div>
                  <div class="input-material">
                    <input id="document" name="text" class="form-control" type="text" inputmode="numeric"
                           [(ngModel)]="model.document" mask="000.000.000-00" required/>
                    <label for="document">{{"author.document" | translate}}</label>
                  </div>
                  <div class="d-flex flex-column flex-md-row">
                    <div class="flex-grow-1">
                      <div class="input-material">
                        <input id="phone" name="text" class="form-control" type="text"
                               [(ngModel)]="model.phoneExtensionLine" autocomplete="off" inputmode="numeric"
                               [mask]="'+00 (00) 00000- 0000 ||+00 (00) 0000-0000'"
                               required/>
                        <label for="phone">{{"author.cell" | translate}}</label>
                      </div>
                    </div>
                    <div style="width: 1em;height: 0">&nbsp;</div>
                    <div class="flex-grow-1">
                      <div class="input-material">
                        <input id="cost" name="text" class="form-control" type="text"
                               [(ngModel)]="model.costCenter" required/>
                        <label for="cost">{{"author.costCenter" | translate}}</label>
                      </div>
                    </div>
                  </div>
                  <div class="input-material">
                    <input id="hourContate" name="text" class="form-control" type="text"
                           [(ngModel)]="model.contactHour" required/>
                    <label for="hourContate">{{"author.bestTime" | translate}}</label>
                  </div>

                  <a href="javaScript:void(0)" class="btn btn-primary modal--idea_btn-author mb-3"
                     (click)="openModalAuthor('newAuthor')">
                    {{"author.addAuthors" | translate}}
                    <svg-icon src="assets/images/plus-circle.svg"></svg-icon>
                  </a>

                  <div class="modal--idea_card my-2" *ngFor="let a of model.authors; let i = index;">
                    <div class="d-flex justify-content-between mb-3">
                      <span class="modal--idea_card-title">{{"author.authorAdded" | translate}} {{i + 2}}</span>
                      <span (click)="onRemoveAuthor(i)"><svg-icon
                        src="assets/images/close-circle.svg"></svg-icon></span>
                    </div>
                    <div class="input-material">
                      <!--<input id="name" name="text" class="form-control" type="text"
                             [value]="a.name"
                             [readOnly]="true"
                             [disabled]="true" required/>-->
                      <span>Nome: {{a.name}}</span>
                      <!--<label class="disabled">{{"author.name" | translate}}</label>-->
                    </div>
                    <div class="input-material">
                      <!--<input id="reAuthor" name="text" class="form-control" type="text"
                             [value]="a.re"
                             [readOnly]="true"
                             [disabled]="true" required/>-->
                      <span>RE: {{a.re}}</span>
                      <!--<label class="disabled">RE</label>-->
                    </div>
                    <div class="input-material">
                      <span>CPF: {{a.cpf | mask:"000.000.000-00"}}</span>
                    </div>
                    <div class="input-material">
                      <span>Area: {{a.area}}</span>
                    </div>
                    <div class="input-material">
                      <span>Centro de Custo: {{a.costCenter}}</span>
                    </div>
                    <div class="input-material">
                      <span>Cargo: {{a.jobRole}}</span>
                    </div>
                    <div class="input-material">
                      <span>Email: {{a.email}}</span>
                    </div>
                  </div>

                  <button type="button" (click)="onStepSaveThree()" class="btn-more effect">
                    <span>{{"advance" | translate}}</span>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!---->
      <ng-container *ngSwitchCase="'unity'" [ngTemplateOutlet]="unity"></ng-container>
      <ng-container *ngSwitchCase="'productiveEquipment'" [ngTemplateOutlet]="productiveEquipment"></ng-container>
      <ng-container *ngSwitchCase="'responsibleArea'" [ngTemplateOutlet]="responsibleArea"></ng-container>
      <ng-container *ngSwitchCase="'contact'" [ngTemplateOutlet]="contact"></ng-container>
      <ng-container *ngSwitchCase="'finish'" [ngTemplateOutlet]="finish"></ng-container>
    </ng-container>
  </div>
</div>

<ng-template #challenge>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-6 over-gradient">
          <div class="modal--idea__question">
            <div class="modal--idea__wrapper">
              <ng-container [ngTemplateOutlet]="language"></ng-container>
              <h3 class="modal--idea__title">{{"newIdea" | translate}}</h3>
              <h4 class="modal--idea__step">01</h4>
              <h5 class="modal--idea__section">{{"operationIdea" | translate}}</h5>
            </div>
            <div class="modal--idea__col" [ngClass]="{'justify-content-end':recordStream}">
              <ng-container *ngIf="!recordStream">
                <button type="button" class="modal--idea__back" (click)="onNavScroll('programa')" data-dismiss="modal">
                  <svg-icon class="svg" src="assets/images/back.svg"></svg-icon>
                  <span>{{"back" | translate}}</span>
                </button>
              </ng-container>
              <div class="d-flex  align-items-center">
                <div class="modal--idea__dots mx-2">
                  <div class="modal--idea__dots-item dots-now"></div>
                  <div class="modal--idea__dots-item"></div>
                  <div class="modal--idea__dots-item"></div>
                  <div class="modal--idea__dots-item"></div>
                  <div class="modal--idea__dots-item"></div>
                  <div class="modal--idea__dots-item"></div>
                  <div class="modal--idea__dots-item"></div>
                </div>
                <svg-icon class="svg" src="assets/images/logo-icon-white.svg"></svg-icon>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 over-custom">
          <div class="modal--idea_header">
            <div class="bg-white" style="height: 90px;">
              <svg-icon src="assets/images/logo.svg"></svg-icon>
            </div>
          </div>
          <div class="modal--idea__form" style="justify-content: center;">
            <form class="modal--idea__wrapper mx-auto">
              <label class="checkbox-container mb-4" *ngFor="let x of challenges">
                <input type="radio" name="answers" [value]="x.id"
                       [checked]="model.challenge?.id === x.id"
                       (change)="stepOneChallenge($event)" />
                <span class="checkmark">

                  <span>{{x.title}}<span>{{x.description}}</span></span>
                </span>
              </label>

              <button type="button" (click)="onStepSaveOne()" class="btn-more effect">
                <span>{{"advance" | translate}}</span>
              </button>

            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #description>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-6 over-gradient">
          <div class="modal--idea__question">
            <div class="modal--idea__wrapper">
              <ng-container [ngTemplateOutlet]="language"></ng-container>
              <h3 class="modal--idea__title">{{"newIdea" | translate}}</h3>
              <h4 class="modal--idea__step">02</h4>
              <h5 class="modal--idea__section">{{"ideaDescription" | translate}} </h5>
            </div>
            <div class="modal--idea__col">
              <button type="button" class=" modal--idea__back" (click)="onBackModal('challenge')">
                <svg-icon class="svg" src="assets/images/back.svg"></svg-icon>
                <span>{{"back" | translate}}</span>
              </button>
              <div class="d-flex align-items-center">
                <div class="modal--idea__dots mx-2">
                  <div class="modal--idea__dots-item dots-back"></div>
                  <div class="modal--idea__dots-item dots-now"></div>
                  <div class="modal--idea__dots-item"></div>
                  <div class="modal--idea__dots-item"></div>
                  <div class="modal--idea__dots-item"></div>
                  <div class="modal--idea__dots-item"></div>
                  <div class="modal--idea__dots-item"></div>
                </div>
                <svg-icon class="svg" src="assets/images/logo-icon-white.svg"></svg-icon>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 over-custom">
          <div class="modal--idea_header">
            <div class="bg-white" style="height: 90px;">
              <svg-icon src="assets/images/logo.svg"></svg-icon>
            </div>
          </div>
          <div class="modal--idea__form " style="justify-content: initial;">
            <form class="modal--idea__wrapper mx-auto">
              <div class="input-material">
                <input id="title" name="title" class="form-control" type="text"
                       maxlength="250"
                       [(ngModel)]="model.title" required/>
                <label for="title">{{"ideaMaker.ideaTitle" | translate}}</label>
              </div>
              <div class="input-material mb-5">
                <textarea class="form-control" name="message"
                          placeholder="{{'ideaDescription' | translate}}"
                          id="descriptionIdea" rows="5"
                          [(ngModel)]="model.description"></textarea>
                <label for="descriptionIdea">{{"ideaMaker.ideaDesc" | translate}}</label>
              </div>

              <ng-container *ngIf="true">
                <p class="modal--idea_lbl">{{"ideaMaker.ideaSpec" | translate}}</p>
                <ng-container *ngFor="let x of modelExpectations; let i = index;">
                  <label class="checkbox-container">
                    <input type="checkbox" name="expectation"
                           [value]="x.expectation"
                           [checked]="x.isSelect"
                           (change)="onChangeExpectation(x)"/>
                    <span class="checkmark">
                      <span class="align-self-center">{{x.expectation | translate}}</span>
                    </span>
                  </label>
                  <div class="input-material mt-4 mb-5" *ngIf="x.isSelect">
                    <textarea class="form-control" name="message{{i}}"
                              id="descriptionF_{{i}}" rows="5"
                              [(ngModel)]="modelExpectations[i].expectationDescription"></textarea>
                    <label for="descriptionF_{{i}}">{{"ideaMaker.ideaDesc" | translate}}</label>
                  </div>
                </ng-container>
              </ng-container>

              <button type="button" (click)="onStepSaveSecond()" class="btn-more effect">
                <span>{{"advance" | translate}}</span>
              </button>

            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<div class="modal fade modal_author" id="newAuthor" tabindex="-1" role="dialog" aria-labelledby="loginLabel"
     aria-hidden="true"
     data-backdrop="static">
  <div class="modal-dialog modal-md modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="d-flex justify-content-between align-items-center mb-5">
          <span
            class="modal_author-title">{{"author.authorAdded" | translate}} {{this.model.authors?.length + 2}}</span>
          <svg-icon data-dismiss="modal" src="assets/images/close.svg"></svg-icon>
        </div>
        <div class="input-material" style="font-size: 19px;">
          <input id="newName" name="text" class="form-control" type="text" [(ngModel)]="modelAuthorDetail.name"
                 maxlength="250"
                 required/>
          <label for="newName">{{"author.name" | translate}}</label>
        </div>
        <div class="input-material" style="font-size: 19px;">
          <input id="newRe" name="text" class="form-control" type="text" [(ngModel)]="modelAuthorDetail.re"
                 appOnlyNumber inputmode="numeric" maxlength="6" required/>
          <label for="newRe">RE</label>
        </div>
        <div class="input-material" style="font-size: 19px;">
          <input id="newCpf" name="text" class="form-control" type="text" [(ngModel)]="modelAuthorDetail.cpf"
                 inputmode="numeric" mask="000.000.000-00" required/>
          <label for="document">{{"author.document" | translate}}</label>
        </div>
        <div class="input-material" style="font-size: 19px;">
          <select class="form__select" name="areas" [(ngModel)]="modelAuthorDetail.area">
            <option [ngValue]="undefined">Area</option>
            <option *ngFor="let a of areas" [ngValue]="a.name">{{a.name}}</option>
          </select>
        </div>

        <div class="input-material" style="font-size: 19px;">
          <input id="newCost" name="text" class="form-control" type="text" [(ngModel)]="modelAuthorDetail.costCenter"
                 maxlength="250"
                 required/>
          <label for="newCost">Centro de Custo</label>
        </div>
        <div class="input-material" style="font-size: 19px;">
          <input id="newCargo" name="text" class="form-control" type="text" [(ngModel)]="modelAuthorDetail.jobRole"
                 maxlength="250"
                 required/>
          <label for="newCargo">Cargo</label>
        </div>
        <div class="input-material" style="font-size: 19px;">
          <input id="newEmail" name="text" class="form-control" type="text" [(ngModel)]="modelAuthorDetail.email"
                 maxlength="250"
                 required/>
          <label for="newEmail">Email</label>
        </div>
        <button data-dismiss="modal" type="button" class="btn-more btn-block effect" (click)="onAddAuthor()">
          <span>{{"save" | translate}}</span>
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #unity>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-6 over-gradient">
          <div class="modal--idea__question">
            <div class="modal--idea__wrapper">
              <ng-container [ngTemplateOutlet]="language"></ng-container>
              <h3 class="modal--idea__title">{{"newIdea" | translate}}</h3>
              <h4 class="modal--idea__step">04</h4>
              <h5 class="modal--idea__section">{{"unity.select" | translate}}</h5>
            </div>
            <div class="modal--idea__col">
              <button type="button" class="modal--idea__back" (click)="onBackModal('author')">
                <svg-icon class="svg" src="assets/images/back.svg"></svg-icon>
                <span>{{"back" | translate}}</span>
              </button>
              <div class="d-flex align-items-center">
                <div class="modal--idea__dots mx-2">
                  <div class="modal--idea__dots-item dots-back"></div>
                  <div class="modal--idea__dots-item dots-back"></div>
                  <div class="modal--idea__dots-item dots-back"></div>
                  <div class="modal--idea__dots-item dots-now"></div>
                  <div class="modal--idea__dots-item"></div>
                  <div class="modal--idea__dots-item"></div>
                  <div class="modal--idea__dots-item"></div>
                </div>
                <svg-icon class="svg" src="assets/images/logo-icon-white.svg"></svg-icon>
              </div>
            </div>

          </div>
        </div>
        <div class="col-12 col-md-6 over-custom">
          <div class="modal--idea_header">
            <div class="bg-white" style="height: 90px;">
              <svg-icon src="assets/images/logo.svg"></svg-icon>
            </div>
          </div>
          <div class="modal--idea__form " style="justify-content: initial;">
            <div class="country_selection">

              <form class="modal--idea__wrapper mx-auto">
                <p class="country_selection-lbl">{{"unity.country" | translate}}</p>
                <div class="country_selection-grid">
                  <ng-container *ngFor="let i of countries | async">
                    <div [ngClass]="{'active':selectedCountry == i}" class="country_selection-card"
                         (click)="onGetUnityByCountry(i)">
                      <img class="country_selection-card-img" [src]="i.flag" alt="country"/>
                      <p class="country_selection-card-name">{{i.name}}</p>
                    </div>
                  </ng-container>
                </div>
                <p class="country_selection-lbl">{{"unit" | translate}}</p>
                <div *ngFor="let u of countriesUnity | async">
                  <label class="checkbox-container">
                    <input type="radio" name="answers"
                           [value]="u.id"
                           [checked]="this.model.unity?.id === u.id"
                           (change)="onChangeUnity($event)"
                    />
                    <span class="checkmark">
                      <span class="align-self-center">{{u.name}}</span>
                    </span>
                  </label>
                </div>
                <button type="button" (click)="onStepSaveFour()" class="btn-more effect">
                  <span>{{"advance" | translate}}</span>
                </button>
              </form>

            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #productiveEquipment>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-6 over-gradient">
          <div class="modal--idea__question">
            <div class="modal--idea__wrapper">
              <ng-container [ngTemplateOutlet]="language"></ng-container>
              <h3 class="modal--idea__title">{{"newIdea" | translate}}</h3>
              <h4 class="modal--idea__step">05</h4>
              <h5 class="modal--idea__section">
                {{"implementIdea.equip" | translate}}
              </h5>
            </div>
            <div class="modal--idea__col">
              <button type="button" class=" modal--idea__back" (click)="onBackModal('unity')">
                <svg-icon class="svg" src="assets/images/back.svg"></svg-icon>
                <span>{{"back" | translate}}</span>
              </button>
              <div class="d-flex align-items-center">
                <div class="modal--idea__dots mx-2">
                  <div class="modal--idea__dots-item dots-back"></div>
                  <div class="modal--idea__dots-item dots-back"></div>
                  <div class="modal--idea__dots-item dots-back"></div>
                  <div class="modal--idea__dots-item dots-back"></div>
                  <div class="modal--idea__dots-item dots-now"></div>
                  <div class="modal--idea__dots-item"></div>
                  <div class="modal--idea__dots-item"></div>
                </div>
                <svg-icon class="svg" src="assets/images/logo-icon-white.svg"></svg-icon>
              </div>
            </div>

          </div>
        </div>
        <div class="col-12 col-md-6 over-custom">
          <div class="modal--idea_header">
            <div class="bg-white" style="height: 90px">
              <svg-icon src="assets/images/logo.svg"></svg-icon>
            </div>
          </div>
          <div class="modal--idea__form " style="justify-content: center;">

            <form class="modal--idea__wrapper mx-auto">
              <p>{{"implementIdea.equip" | translate}}</p>
              <div style="display: grid;grid-template-columns: 1fr 1fr;gap: 1em;margin-top: 4em;">
                <label class="checkbox-container mb-0">
                  <input type="radio" name="answers"
                         [value]="true"
                         [ngModel]="model.productiveEquipament"
                         (ngModelChange)="model.productiveEquipament = $event"/>
                  <span class="checkmark">
                  <span class="align-self-center">{{"yes" | translate}}</span>
                </span>
                </label>
                <label class="checkbox-container">
                  <input type="radio" name="answers"
                         [value]="false"
                         [ngModel]="model.productiveEquipament"
                         (ngModelChange)="model.productiveEquipament = $event"/>
                  <span class="checkmark">
                  <span class="align-self-center">{{"no" | translate}}</span>
                </span>
                </label>
              </div>
              <div class="input-material mt-5 mb-2" *ngIf="model.productiveEquipament === true">
                <textarea class="form-control" name="message"
                          [(ngModel)]="model.productiveDescription"
                          placeholder="{{'implementIdea.desc' | translate}}"
                          id="which" rows="8"></textarea>
                <label for="which">{{"implementIdea.which" | translate}}</label>
              </div>
              <button type="button" (click)="onStepSaveFive()" class="btn-more effect">
                <span>{{"advance" | translate}}</span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #responsibleArea>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-6 over-gradient">
          <div class="modal--idea__question">
            <div class="modal--idea__wrapper">
              <ng-container [ngTemplateOutlet]="language"></ng-container>
              <h3 class="modal--idea__title">{{"newIdea" | translate}}</h3>
              <h4 class="modal--idea__step">06</h4>
              <h5 class="modal--idea__section">
                {{"ideaArea.area" | translate}}
              </h5>
            </div>
            <div class="modal--idea__col">
              <button type="button" class=" modal--idea__back" (click)="onBackModal('productiveEquipment')">
                <svg-icon class="svg" src="assets/images/back.svg"></svg-icon>
                <span>{{"back" | translate}}</span>
              </button>
              <div class="d-flex align-items-center">
                <div class="modal--idea__dots mx-2">
                  <div class="modal--idea__dots-item dots-back"></div>
                  <div class="modal--idea__dots-item dots-back"></div>
                  <div class="modal--idea__dots-item dots-back"></div>
                  <div class="modal--idea__dots-item dots-back"></div>
                  <div class="modal--idea__dots-item dots-back"></div>
                  <div class="modal--idea__dots-item dots-now"></div>
                  <div class="modal--idea__dots-item"></div>
                </div>
                <svg-icon class="svg" src="assets/images/logo-icon-white.svg"></svg-icon>
              </div>
            </div>

          </div>
        </div>
        <div class="col-12 col-md-6 over-custom">
          <div class="modal--idea_header">
            <div class="bg-white" style="height: 90px;">
              <svg-icon style=""
                        src="assets/images/logo.svg"></svg-icon>
            </div>
          </div>
          <div class="modal--idea__form" style="justify-content: center;">

            <form class="modal--idea__wrapper mx-auto">
              <div class="input-material">
                <select class="form__select" name="areas" [(ngModel)]="model.areaResponsible">
                  <option [ngValue]="undefined">{{"ideaArea.responsible" | translate}}</option>
                  <option *ngFor="let a of areas" [ngValue]="a.name">{{a.name}}</option>
                </select>
              </div>
              <div class="input-material" style="margin-bottom: 10em;">
                <input id="ramal" name="email" class="form-control" type="text"
                       maxlength="6" inputmode="numeric"
                       [(ngModel)]="model.areaExtensionLine" appOnlyNumber required/>
                <label for="ramal">{{"author.ramal" | translate}}</label>
              </div>
              <button type="button" (click)="onStepSaveSix()" class="btn-more effect mb-5">
                <span>{{"advance" | translate}}</span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #contact>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-6 over-gradient">
          <div class="modal--idea__question">
            <div class="modal--idea__wrapper">
              <ng-container [ngTemplateOutlet]="language"></ng-container>
              <h3 class="modal--idea__title">{{"newIdea" | translate}}</h3>
              <h4 class="modal--idea__step">07</h4>
              <h5 class="modal--idea__section">
                {{"ideaEmail.inform" | translate}}
              </h5>
            </div>
            <div class="modal--idea__col">
              <button type="button" (click)="onBackModal('responsibleArea')" class=" modal--idea__back"
                      data-dismiss="modal">
                <svg-icon class="svg" src="assets/images/back.svg"></svg-icon>
                <span>{{"back" | translate}}</span>
              </button>
              <div class="d-flex align-items-center">
                <div class="modal--idea__dots mx-2">
                  <div class="modal--idea__dots-item dots-back"></div>
                  <div class="modal--idea__dots-item dots-back"></div>
                  <div class="modal--idea__dots-item dots-back"></div>
                  <div class="modal--idea__dots-item dots-back"></div>
                  <div class="modal--idea__dots-item dots-back"></div>
                  <div class="modal--idea__dots-item dots-back"></div>
                  <div class="modal--idea__dots-item dots-now"></div>
                </div>
                <svg-icon class="svg" src="assets/images/logo-icon-white.svg"></svg-icon>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 over-custom">
          <div class="modal--idea_header">
            <div class="bg-white" style="height: 90px;">
              <svg-icon src="assets/images/logo.svg"></svg-icon>
            </div>
          </div>
          <div class="modal--idea__form contact" style="justify-content: center;">

            <form class="modal--idea__wrapper mx-auto">
              <p class="contact_text-primary" style="margin: 0 0 4.5rem;">
                {{"ideaEmail.informCorrect" | translate}}
              </p>
              <div class="input-material">
                <input id="email" name="email" class="form-control" type="text"
                       maxlength="250"
                       [(ngModel)]="model.email" required/>
                <label for="email">{{"email" | translate}}</label>
              </div>
              <div class="input-material mb-5">
                <input id="emailConfirm" name="email" class="form-control" type="text"
                       maxlength="250"
                       [(ngModel)]="modelEmailConfirm" required/>
                <label for="emailConfirm">{{"ideaEmail.repeat" | translate}}</label>
              </div>
              <p class="contact_text-secondary" style="margin-top: 4.3em;">{{"ideaEmail.note" | translate}}</p>
              <p class="contact_text-secondary font-italic" style="margin-bottom: 3.8em;">
                {{"ideaEmail.title" | translate}} <b>Nº</b> {{"idea" | translate}} Clic
                {{"ideaEmail.send" | translate}} <span
                style="text-decoration: underline">seis.sigma@eurofarma.com</span>
              </p>

              <button type="button" (click)="onStepSaveSeven()" class="btn-more mt-0 effect mb-5">
                <span>{{"submit" | translate}}</span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #finish>
  <div class="modal-body">
    <section class="idea-finish" style="">
      <div class="idea-finish_col-primary">
        <svg-icon [svgClass]="'idea-finish_svg-primary'" src="assets/images/logo-icon-white.svg"></svg-icon>
      </div>
      <div class="idea-finish_col-secondary">
        <div class="idea-finish_col-secondary_padding">
          <svg-icon class="idea-finish_svg-secondary" src="assets/images/logo.svg"></svg-icon>
          <h3 class="idea-finish_text-primary">
            {{"confirmPage.actions" | translate}}
            <br>
            <br>
            {{"confirmPage.thanks" | translate}}
          </h3>
          <p class="idea-finish_text-secondary"  [innerHTML]="'confirmPage.follow' | translate"></p>
          <!--            {{"confirmPage.follow" | translate}-->
          <br>
          <!--          <p>-->
          <!--            {{"confirmPage.follow1" | translate}}-->
          <!--          </p>-->
          <form class="d-flex flex-column flex-lg-row" style="gap: 30px;">
            <button (click)="onBackModal('challenge')" type="button" style="width: initial"
                    class="btn-more effect flex-grow-1 mt-0">
              <span>{{"confirmPage.registerAnother" | translate}}</span>
            </button>
            <ng-container *ngIf="!recordStream">
              <button (click)="onNextModal('challenge')" type="button"
                      class="btn btn-close btn-outline-white flex-grow-1">
                <span>{{"close" | translate}}</span>
              </button>
            </ng-container>
          </form>
        </div>
      </div>

    </section>
  </div>
</ng-template>

<ng-template #language>
  <div class="text-white">
    <div class="header__language align-items-start" style="display: flex !important;">
      <strong>Idioma</strong>
      <ul class="d-flex" style="list-style-type: none">
        <li><a href="javascript:void('');" [ngClass]="{'active' : translate.currentLang === 'pt'}"
               (click)="switchLanguage('pt', false);">PT</a></li>
        <li><span>|</span></li>
        <!--<li><a href="javascript:void('');" (click)="switchLanguage('en');">EN</a></li>
        <li><span>|</span></li>-->
        <li><a href="javascript:void('');" [ngClass]="{'active' : translate.currentLang === 'es'}"
               (click)="switchLanguage('es', false);">ES</a></li>
      </ul>
    </div>
  </div>
</ng-template>
