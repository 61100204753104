<app-header></app-header>

<main>
  <router-outlet></router-outlet>
</main>

<section *ngIf="false">
  <h1>GRAPHQL</h1>

  <h2 *ngFor="let x of users | async">
    {{x.name}} | <small>{{x.email}} -- {{x.role}}</small>
  </h2>
  <fieldset>
    <div>
      <label>Nome</label>
      <input [(ngModel)]="modelUser.name" />
    </div>
    <div>
      <label>E-mail</label>
      <input [(ngModel)]="modelUser.email" />
    </div>
    <div>
      <label>Senha</label>
      <input [(ngModel)]="modelUser.password" />
    </div>
    <div>
      <label>Perfil</label>
      <input [(ngModel)]="modelUser.role" />
    </div>
  </fieldset>

  <a href="javascript:void('');" class="btn btn-primary" (click)="onCreateUser()">Criar</a>
</section>

<app-footer></app-footer>

<!--<app-register-idea></app-register-idea>-->
