import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {BaseComponent} from "../../base/base.component";
import {Apollo} from "apollo-angular";
import {GetChallengeGQL, GetNewGQL, User} from "../../../generated/graphql";
import {TranslateService} from "@ngx-translate/core";
import {UserService} from "../../service/user.service";

declare var $: any;

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent extends BaseComponent implements OnInit {

  user: User;

  constructor(public router: Router,
              public translate: TranslateService,
              public userService: UserService) {
    super(router, translate);
  }

  ngOnInit(): void {
    this.userService.user.subscribe({
      next: data => {
        this.user = data as User;
      }, error: err => super.onError(err)
    });
  }

  navigatorHome() {
    document.querySelector<HTMLElement>('.header').classList.remove('dark');
    this.router.navigate(['/register-idea']);
  }
}
