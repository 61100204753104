import {Component, OnInit, OnDestroy} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Apollo, QueryRef} from 'apollo-angular';
import {
  AddLikeGQL,
  CreateIdeaCommentGQL,
  GetChallengeGQL,
  GetIdeaGQL, GetIdeaQuery, Idea
} from '../../../generated/graphql';

@Component({
  selector: 'app-idea-detail',
  templateUrl: './idea-detail.component.html',
  styleUrls: ['./idea-detail.component.scss']
})
export class IdeaDetailComponent extends BaseComponent implements OnInit, OnDestroy {

  paramId = null;
  idea: Idea = new Idea();
  ideaQuery: QueryRef<GetIdeaQuery>;
  model: any = {};

  constructor(public router: Router,
              public route2: ActivatedRoute,
              private apollo: Apollo,
              private getChallengeGQL: GetChallengeGQL,
              private getIdeaGQL: GetIdeaGQL,
              private createIdeaCommentGQL: CreateIdeaCommentGQL,
              private addLikeGQL: AddLikeGQL,
              public translate: TranslateService) {
    super(router, translate);
  }

  ngOnInit(): void {
    document.body.classList.add('inside');
    this.route2.params.subscribe(url => {
      if (!this.isNullOrUndefined(url)) {
        this.paramId = url.id;
        this.ideaQuery = this.getIdeaGQL.watch({id: url.id});
        this.ideaQuery.valueChanges.subscribe(({data}) => {
          Object.assign(this.idea, data.getIdea as Idea);
          this.idea.expectations = this.idea.expectations
            .filter(a => this.isNullOrUndefined(a.challengeLanguage)
              || a.challengeLanguage.includes(this.translate.currentLang));
        });
      }
    });
  }

  ngOnDestroy(): void {
    document.body.classList.remove('inside');
  }

  actionLike() {
    this.addLikeGQL.mutate({id: this.paramId}).subscribe(async ({data}) => {
      this.showMessage(this.translate.instant('success'), this.translate.instant('likeSend'), 'success');
      setTimeout(() => {
        this.ideaQuery.refetch({id: this.paramId});
      });
    });
  }

  onSaveIdeaComment() {
    const arrValidateFields = [
      {value: this.model.name, text: this.translate.instant('ideaDetail.name') + ', <br />'},
      {value: this.model.message, text: this.translate.instant('ideaDetail.message') + '<br />'},
    ];
    const stringError = this.validateField(arrValidateFields);
    if (!super.isNullOrUndefined(stringError)) {
      this.showMessage(this.translate.instant('attention'), this.translate.instant('required') + `<br><br> ${stringError}`);
      return;
    }
    const a: any = {data: this.model, id: this.paramId};
    this.createIdeaCommentGQL.mutate(a).subscribe(
      () => {
        this.model = {};
        this.showMessage(this.translate.instant('success'), this.translate.instant('commentSend'), 'success');
        this.ideaQuery.refetch({id: this.paramId});
        return;
      }, (err) => {
      }
    );
  }

}
