import {Component, OnInit} from '@angular/core';
import * as svg4everybody from 'svg4everybody/dist/svg4everybody';
import {BaseComponent} from "./base/base.component";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends BaseComponent implements OnInit {

  constructor(public router: Router,
              public translate: TranslateService) {
    super(router, translate);
  }

  ngOnInit(): void {
    svg4everybody();

    if (this.isNullOrUndefined(localStorage.getItem('culture'))) {
      if (navigator.language.slice(0, 2) === 'pt') {
        super.switchLanguage('pt');
      // } else if (navigator.language.slice(0, 2) === 'es') {
      //   super.switchLanguage('es');
      } else {
        super.switchLanguage('es');
      }
    }
    if (localStorage.getItem('culture') === 'pt-BR') {
      this.switchLanguage('pt');
    } else {
      this.switchLanguage('es');
    }

    // if (localStorage.getItem('culture') === 'en-US') {
    //   this.switchLanguage('en');
    // }

    // if (localStorage.getItem('culture') === 'es-ES') {
    //   this.switchLanguage('es');
    // }
  }
}
