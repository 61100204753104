<span class="header__language">
  <strong>Idioma</strong>
  <ul>
    <li><a href="javascript:void('');" [ngClass]="{'active' : translate.currentLang === 'pt'}"
           (click)="switchLanguage('pt', true);">PT</a></li>
    <li><span>|</span></li>
    <!--<li><a href="javascript:void('');" (click)="switchLanguage('en');">EN</a></li>
    <li><span>|</span></li>-->
    <li><a href="javascript:void('');" [ngClass]="{'active' : translate.currentLang === 'es'}"
           (click)="switchLanguage('es', true);">ES</a></li>
  </ul>
</span>
