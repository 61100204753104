<section class="hero hero-bar">
  <div class="container" style="max-width: 1150px;">
    <div class="hover-hero d-none d-lg-block">
      <img src="assets/images/circle-hero.png" alt="img-hero" style="opacity: 0.9;max-height: 907px;height: 71vw;">
      <div class="hover-hero_icon">
        <img src="assets/images/icon-bg.png" alt="img-hero" style="width: 35vh; max-width: 296px;">
      </div>
    </div>
    <h1 class="title hero__title">{{'title.in' | translate}}<span
      class="highlight"><strong>Clic</strong></span>{{'title.change' | translate}}<span
      class="highlight"><strong>{{'title.better' | translate}}</strong></span></h1>
    <div class="d-flex justify-content-center" style="width: 100%;gap: 30px;flex-wrap: wrap">
      <!--  Cliente solicitou ocultar o botão ‘insira sua idéia’ nesse primeiro momento da entrada em produção 15/12/2021-->
      <button routerLink="/register-idea" class="btn-more more-secondary effect m-0">
        <span>{{'navTab.subscribeIdea' | translate}}</span>
      </button>
      <button type="button" class="btn-more effect m-0" (click)="onNavScroll('ideias')">
        <span>{{'seeThe'|translate}}  {{'seeImplementIdeas' | translate}}</span>
      </button>

    </div>
  </div>
  <svg-icon class="svg scroll" src="assets/images/scroll.svg"></svg-icon>
</section>

<section class="page about" id="programa">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-6">
        <h2 class="title uppercase about__title">{{'primaryText.theProgram' | translate}}
          <br><span>Clic</span> {{"primaryText.hasBeen" | translate}}<br> {{"primaryText.dev" | translate}}
          <br> {{"primaryText.fy" | translate}}
        </h2>
        <p>{{'secondText.presentIdea' | translate}}
          <strong>{{'secondText.new' | translate}}</strong> {{'secondText.product' | translate}}
          <strong>{{'secondText.routineImprove' | translate}}</strong> {{'secondText.work' | translate}}
          <strong>{{'secondText.problem' | translate}}</strong> {{'secondText.advanceProposal' | translate}}</p>
        <p>{{'secondText.important' | translate}}
          <strong>{{'secondText.results' | translate}}</strong>{{'secondText.company' | translate}}</p>
        <p class="mt-5"><a href="{{this.clickPoliciesUrl}}" target="_blank" class="btn-more d-inline-block effect m-0">
          <span>{{'navTab.clickPolicies' | translate}}</span>
        </a></p>
      </div>
      <div class="col-12 col-md-6 text-center text-md-right">
        <img src="assets/images/vector.svg" alt="">
      </div>
    </div>
  </div>
</section>

<section class="page ideas" id="ideias">
  <div class="container">
    <h2 class="title ideas__title"><span class="highlight"><strong>{{'ideas' | translate}}</strong></span> CLIC</h2>
    <div class="ideas__search">
      <h3 class="title small uppercase">{{'ideaPage.participate' | translate}}<br>{{'of' | translate}}<span
        class="highlight"><strong>{{'program' | translate}}?</strong></span></h3>
      <div style="max-width: 600px;margin-left: auto;margin-right: auto">
        <p class="mb-2">
          {{'ideaPage.consult' | translate}}
        </p>
        <p class="mb-2">
          {{'ideaPage.note' | translate}}
        </p>
      </div>

      <div class="form">
        <form class="form__wrapper">
          <input class="form__field large" onkeydown="return event.keyCode !== 69" type="number" mask=""
                 [(ngModel)]="filter"
                 name="keyword"
                 placeholder="RE…">
          <button (click)="actionFilterIdeaAll();" class="form__field large button">
            <svg-icon class="svg" src="assets/images/search.svg"></svg-icon>
          </button>
        </form>
      </div>
    </div>
  </div>
  <div id="slick-slider-ideal" class="carousel carousel-adjusted" *ngIf="isSearchClic"
       style="opacity: 0; visibility: hidden;">

    <p *ngIf="allIdeas?.length == 0" class="d-flex justify-content-center py-4">{{"noIdeaFound" | translate}}</p>
    <div class="carousel-adjusted__item" *ngFor="let c of allIdeas">
      <article (click)="onNavigateRouter('idea-detail/'+ c?.id)" class="post effect">
        <h4 class="post__title">{{c?.code}} - {{c?.title}}</h4>
        <p class="post__text">{{c?.description}}</p>
        <div class="post__actions">
          <p class="post__date">{{'registerDate' | translate}} <strong>{{c.createdAt | date:'dd/MM/yyyy'}}</strong></p>
          <span>
            <span [attr.class]="'post__status btn no-events ' + getStatusClassByStr(c?.status)">
              <svg-icon [src]="getStatusIconByStr(c?.status)" class="svg"></svg-icon>
                <span>{{getStatusNameByStr(c?.status)}}</span>
            </span>
            <button class="btn btn-primary large">
              <svg-icon class="svg" src="assets/images/eye.svg"></svg-icon>
            </button>
          </span>
        </div>
      </article>
    </div>
  </div>
</section>

<section class="page filter">
  <div class="container">
    <h3 class="title small text-center uppercase">{{"seeThe" | translate}} <span
      class="highlight"><strong>{{"seeImplementIdeas" | translate}}</strong></span>
    </h3>
    <div class="form small">
      <form class="mt-5 mb-5 form__wrapper">
        <label class="form__label">{{"filter" | translate}}</label>
        <select class="form__select" name="countries"
                [(ngModel)]="modelIdeaFilter.countryId"
                (ngModelChange)="handlerCountry($event)">
          <option [ngValue]="undefined">{{"country" | translate}}</option>
          <option *ngFor="let c of countries" [ngValue]="c.id">{{c.name}}</option>
        </select>
        <select class="form__select" name="unity"
                [(ngModel)]="modelIdeaFilter.unityId" (change)="actionFilterIdea()">
          <option [ngValue]="undefined">{{'unit' | translate}}</option>
          <option *ngFor="let u of unities" [ngValue]="u.id">{{u.name}}</option>
        </select>
        <input class="form__field single" type="text"
               name="keyword"
               [(ngModel)]="modelIdeaFilter.keyword"
               placeholder="{{'ideaPage.keyword' | translate}},{{'ideaPage.subject' | translate}}…">
        <button class="btn btn-primary small" (click)="actionFilterIdea()">
          <svg-icon class="svg" src="assets/images/search.svg"></svg-icon>
        </button>
      </form>
      <h4 class="mt-3 form__label">{{"lastIdeas" | translate}}</h4>
    </div>
  </div>
  <div class="filter__dark">
    <p *ngIf="ideas?.length == 0" class="d-flex justify-content-center py-4">{{"noIdeaFound" | translate}}</p>
    <div id="slick-slider-implement" class="carousel carousel-adjusted pt-3">
      <div class="carousel-adjusted__item" *ngFor="let i of getAwardeds()">
        <article (click)="onNavigateRouter('idea-detail/' + i?.id)" class="post effect">
          <h4 class="post__title">{{i?.code}}-{{i?.title}}</h4>
          <p class="post__text">{{i?.description}}</p>
          <div class="post__actions">
            <p class="post__date">{{'registerDate' | translate}} <strong>{{i?.createdAt | date:'dd/MM/yyyy'}}</strong>
            </p>
            <div>
                <span [attr.class]="'post__status btn no-events ' + getStatusClassByStr(i?.status)"
                      style="height: fit-content;">
                <svg-icon [src]="getStatusIconByStr(i?.status)" class="svg"></svg-icon>
                <span>{{getStatusNameByStr(i?.status)}}</span>
              </span>
              <button class="btn btn-primary large">
                <svg-icon class="svg" src="assets/images/eye.svg"></svg-icon>
                <!--<span>{{'more' | translate}}</span>-->
              </button>
            </div>
          </div>
        </article>
      </div>
    </div>
  </div>
</section>

<section class="page numbers" id="numeros">
  <div class="container">
    <div class="numbers__head">
      <h2 class="title numbers__title uppercase pl-3 pl-md-0"><span
        class="highlight"><strong>Clic</strong></span> {{"numbers" | translate}}</h2>
      <p class="pl-3 pl-md-0">{{"counter.inThe" | translate}}
        <strong>{{"counter.months" | translate}}</strong> {{"counter.have" | translate}}</p>
    </div>
  </div>
  <div class="numbers__items">
    <div class="numbers__section first" #newIdeaCounte>
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-7">
            <!--{{homeDash?.news}} **-->
            <span [countUp]="homeDashNumbers.news" class="numbers__number number">0</span>
          </div>
          <div class="col-12 col-md-5 d-flex align-items-center">
            <span class="numbers__text">{{"counter.newIdeas" | translate}} <br>{{"ideas" | translate}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="numbers__section second" #approvedIdeasCounte>
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-7">
            <!--{{homeDash?.approves}} **-->
            <span [countUp]="homeDashNumbers.approves" class="numbers__number number">0</span>
          </div>
          <div class="col-12 col-md-5 d-flex align-items-center">
            <span class="numbers__text">{{"ideas" | translate}} <br>{{"counter.approved" | translate}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="numbers__section third" #financialFeedbackCounte>
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-7">
            <!--{{homeDash?.finances}} **-->
            <span class="numbers__number number light">
              <sup class="pb-5">R$</sup> <span [countUp]="homeDashNumbers.finances" [options]="options"
            >0</span> <!--<sub>{{"counter.thousand" | translate}}</sub>-->
            </span>
          </div>
          <div class="col-12 col-md-5 d-flex align-items-center">
            <span class="numbers__text light">{{"counter.return" | translate}}
              <br>{{"counter.financial" | translate}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class=" production">

  <div class="production">
    <div class="production--col">
      <div class="production--row blue" #manValueCounte>
        <div class="production--row--content">
          <svg-icon src="assets/images/man.svg"></svg-icon>
          <span>Hora <br> <strong>Homem</strong></span>
          <p [countUp]="homeDashNumbers.manValue">0</p>
          <b>MIL</b>
        </div>

      </div>
      <div class="production--row bluedark" #machineValueCounte>
        <div class="production--row--content ">
          <svg-icon src="assets/images/machine.svg"></svg-icon>
          <span>Hora <br> <strong>Máquina</strong></span>
          <p [countUp]="homeDashNumbers.machineValue">0</p>
          <b>MIL</b>
        </div>
      </div>
    </div>
    <div class="production--title">
      <span>Produtividade </span>
    </div>


    <div class="production--col">
      <div class="production--row yellow" #waterValueCounte>
        <div class="production--row--content-2">
          <svg-icon src="assets/images/water.svg"></svg-icon>
          <span><strong>Água</strong></span>
          <div class="d-flex align-items-end">
            <p [countUp]="homeDashNumbers.water">0%</p>
            <small class="mb-1 ml-1">%</small>
          </div>
          <div class="info">
            <b>ALTO</b>
            <span>Impacto</span>
          </div>
        </div>
      </div>
      <div class="production--row yellowlight" #energyValueCounte>
        <div class="production--row--content-2 ">
          <svg-icon src="assets/images/energy.svg"></svg-icon>
          <span><strong>Energia</strong></span>
          <div class="d-flex align-items-end">
            <p [countUp]="homeDashNumbers.energy">0%</p>
            <small class="mb-1 ml-1">%</small>
          </div>
          <div class="info">
            <b>MÉDIO</b>
            <span>Impacto</span>
          </div>
        </div>
      </div>
      <div class="production--row yellowlighter" #wasteValueCounte>
        <div class="production--row--content-2 ">
          <svg-icon src="assets/images/thrash.svg"></svg-icon>
          <span><strong>Resíduos</strong></span>
          <div class="d-flex align-items-end">
            <p [countUp]="homeDashNumbers.waste">0%</p>
            <small class="mb-1 ml-1">%</small>
          </div>
          <div class="info">
            <b>BAIXO</b>
            <span>Impacto</span>
          </div>
        </div>
      </div>
    </div>
    <div class="production--title_2">
      <span>Sustentabilidade </span>
    </div>
  </div>
</section>

<section class="page recognition">
  <div class="container">
    <h2 class="title ideas__title text-lg-right float-lg-left pl-3 pl-md-0"><span
      class="highlight inverted"><strong>{{"you" | translate}}</strong></span>
      {{"singleSearch" | translate}} <br class="d-none d-lg-block">{{"topics.recognition" | translate}}?</h2>
    <ul id="seekRecognition" #seekRecognition class="recognition__progress font-size-150px  pl-1 pl-md-0">
      <li class="step-1"><span class="font-fix">{{"topics.inovation" | translate}}
        <br>{{"topics.change" | translate}}</span></li>
      <li class="step-2"><span class="font-fix">{{"topics.observe" | translate}}<br>{{"topics.oportunity" | translate}}
        <br>{{"topics.improvement" | translate}}<br>{{"topics.environment" | translate}}
        <br>{{"topics.work" | translate}}</span>
      </li>
      <li class="step-3"><span>{{"topics.subscribe" | translate}}</span></li>
      <li class="step-4"><span>{{"topics.approvedIdea" | translate}}<br>{{"topics.receiveA" | translate}}
        <br>{{"topics.reward" | translate}}</span></li>
      <li class="step-5"><span>{{"topics.recognized" | translate}}</span></li>
    </ul>
  </div>
</section>

<section class="page news">
  <div class="container">
    <h2 class="title ideas__title uppercase pl-3 pl-md-0"><span class="highlight"><strong>Clic</strong></span> News</h2>
  </div>
  <div id="slick-slider-news" class="carousel carousel-news pt-3">
    <div class="carousel-news__item" *ngFor="let n of allNews">
      <article class="post" [routerLink]="'/clic-news-detail/' + n.id">
        <img [src]="n?.imageUrl" alt=""/>
        <h4 class="post__title">{{n?.title}}</h4>
        <p class="post__text">{{n?.description}}</p>
        <div class="post__actions">
          <i class="post__date author">{{"by" | translate}} {{n?.author}}, {{n?.createdAt|date:'dd/MM/yyyy'}}</i>
          <ul class="post__tags">
            <li *ngFor="let t of n?.tags">
              <a href="javascript:void(0);" (click)="onNavigateRouter('/clic-news-detail/' + n.id)">{{t?.tag}}</a></li>
          </ul>
        </div>
        <a href="javascript:void(0);"
           class="post__button">{{"seeMore" | translate}}</a>
      </article>
    </div>
  </div>
  <div class="news__more">
    <a href="javascript:void(0)" (click)="onNavigateRouter('/clic-news')" class="btn-more effect">
      <span>{{"seeMore" | translate}}</span>
    </a>
  </div>
</section>
