export class User {
  id: string;
  cpf: string;
  name: string;
  birthdate: Date;
  email: string;
  phone: string;
  sex: string;
  password: string;
  passwordConfirm: string;
  salt: string; // **************************  O QUE É SALT?????????
  cellphone: string;
  city_id: string;
  state_id: string;
  address: string;
  number: string;
  district: string;
  complement: string;
  zipcode: string;
  created_at: Date;
  updated_at: Date;
  is_accept: boolean;
  is_receive_info: boolean;
  is_deleted: boolean;
  deleted_at: Date;

  constructor() {
    this.cpf = '';
    this.phone = '';
    this.cellphone = '';
    this.birthdate = null;
    this.zipcode = '';
  }
}
