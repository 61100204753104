import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Apollo, QueryRef} from 'apollo-angular';
import {
  CreateIdeaCommentGQL,
  GetChallengeGQL,
  GetNewGQL,
  GetNewQuery
} from '../../../generated/graphql';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-clic-news-detail',
  templateUrl: './clic-news-detail.component.html',
  styleUrls: ['./clic-news-detail.component.scss']
})
export class ClicNewsDetailComponent extends BaseComponent implements OnInit, OnDestroy {

  paramId = null;
  new: any;
  newQuery: QueryRef<GetNewQuery>;
  model: any = {};

  constructor(public router: Router,
              public route2: ActivatedRoute,
              private apollo: Apollo,
              private getChallengeGQL: GetChallengeGQL,
              private getNewGQL: GetNewGQL,
              public translate: TranslateService) {
    super(router, translate);
  }


  ngOnInit(): void {
    document.body.classList.add('inside');
    this.route2.params.subscribe(url => {
      if (!this.isNullOrUndefined(url)) {
        this.paramId = url.id;
        this.newQuery = this.getNewGQL.watch({id: url.id});
        this.newQuery.valueChanges.subscribe(({data}) => {
          this.new = data.getNew;
        });

      }
    });
  }

  ngOnDestroy(): void {
    document.body.classList.remove('inside');
  }

}
