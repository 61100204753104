import {Component, OnInit, OnDestroy, AfterViewInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {
  Area, Challenge, CreateIdeaChallengeInput, CreateIdeaCountryInput,
  CreateIdeaGQL, CreateIdeaInput,
  CreateUserGQL, GetAreasGQL, GetAreasQuery, GetChallengesByLanguageGQL, GetChallengesByLanguageQuery,
  GetChallengesGQL,
  GetChallengesQuery, GetCountriesGQL,
  GetCountriesQuery, GetUnitiesByCountryGQL,
  GetUnitiesByCountryQuery, GetUsersGQL,
  GetUsersQuery, IdeaExpectation,
  Query
} from '../../../generated/graphql';
import {Apollo, QueryRef} from 'apollo-angular';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {map} from 'rxjs/operators';
import {EmitEvent, EventBusService, Events} from '../../service/event-bus.service';

declare var $: any;

@Component({
  selector: 'app-register-idea',
  templateUrl: './register-idea.component.html',
  styleUrls: ['./register-idea.component.scss']
})
export class RegisterIdeaComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

  subscription: Subscription;
  page = 'challenge';

  // model: any = {authors: [], productiveDescription: ''}; // MODELO DO CADASTRO
  model: CreateIdeaInput = new CreateIdeaInput();
  modelAuthorDetail: any = {};

  modelEmailConfirm = null;

  challenges: Challenge[] = [];
  challengesQuery: QueryRef<GetChallengesByLanguageQuery>;

  countries: Observable<any>; // Observable<Query['getCountries']>;
  countriesQuery: QueryRef<GetCountriesQuery>;

  countriesUnity: Observable<any>; // Observable<Query['getCountries']>;
  countriesUnityQuery: QueryRef<GetUnitiesByCountryQuery>;
  selectedCountry: any;

  areas: Array<Area> = []; // Observable<Query['getAreas']>;
  areasQuery: QueryRef<GetAreasQuery>;

  modelExpectations: any = [
    {isSelect: false, expectation: 'finance', expectationDescription: ''},
    {isSelect: false, expectation: 'productive', expectationDescription: ''},
    {isSelect: false, expectation: 'sustainability', expectationDescription: ''}
  ];

  recordStream = false;

  constructor(public router: Router,
              public translate: TranslateService,
              private eventbus: EventBusService,
              private apollo: Apollo,
              private getChallengesGQL: GetChallengesGQL,
              private getAreasGQL: GetAreasGQL,
              private getChallengesByLanguageGQL: GetChallengesByLanguageGQL,
              private getCountriesGQL: GetCountriesGQL,
              private createIdeaGQL: CreateIdeaGQL,
              private getUnitiesByCountryGQL: GetUnitiesByCountryGQL) {
    super(router, translate);
    this.subscription = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      $(() => {
        this.challengesQuery.refetch({language: localStorage.getItem('culture')});
      });
    });
  }

  ngOnDestroy(): void {
    $('.header').removeClass('d-none');
    $('.footer').removeClass('d-none');
    const newAuthor = $('#newAuthor');
    if (newAuthor[0]) {
      newAuthor.remove();
    }
  }

  ngOnInit(): void {
    $('.header').addClass('d-none');
    $('.footer').addClass('d-none');
    this.recordStream = this.handlerRegister();
    this.model.authors = [];
    this.challengesQuery = this.getChallengesByLanguageGQL.watch({language: localStorage.getItem('culture')});
    this.getAreas();
    this.challengesQuery.valueChanges.subscribe(({data}) => {
      this.challenges = data.getChallengesByLanguage as Challenge[];
      this.model.challenge = new CreateIdeaChallengeInput();
      this.model.challenge!.language = localStorage.getItem('culture');
      if (this.challenges.length === 1) {
        this.model.challenge!.id = this.challenges[0].id;
      }
    });
    this.countriesQuery = this.getCountriesGQL.watch();
    this.countries = this.countriesQuery.valueChanges.pipe(map(result => result.data.getCountries));
  }

  ngAfterViewInit() {
    console.log('sghdg')
  }

  handlerRegister() {
    return this.router.url === '/register';
  }

  onBackModal(page: string) {
    this.page = page;
  }

  onNextModal(page: string) {
    this.page = page;
    console.log(this.page);
    if (page === 'finish') {
      setTimeout(() => {
        $('.idea-finish_col-primary').css('max-width', '100%');
        $('.idea-finish_col-secondary').css('border-radius', '10px 0 0 10px');
      }, 250);
      setTimeout(() => {
        $('.idea-finish_col-secondary_padding').css('opacity', '1');
      }, 500);
    }
    if (page === 'challenge') {
      $('#idea').modal('hide');
      this.router.navigate(['/']);
    }
  }

  // STEP 1
  stepOneChallenge($event: Event) {
    this.model.challenge = {id: $($event.srcElement).val(), language: localStorage.getItem('culture')};
  }

  onStepSaveOne() {
    if (this.isNullOrUndefined(this.model.challenge)) {
      this.showMessage(this.translate.instant('attention'), this.translate.instant('challengeSelect'));
      return;
    }
    this.onNextModal('description');
  }

  // STEP 2
  onChangeExpectation(x: any) {
    x.isSelect = !x.isSelect;
    if (!x.isSelect) {
      x.expectationDescription = null;
    }
  }

  onStepSaveSecond() {
    const arrValidateFields = [
      {value: this.model.title, text: this.translate.instant('ideaMaker.ideaTitle') + ', <br />'},
      {value: this.model.description, text: this.translate.instant('ideaMaker.ideaDesc') + ', <br />'}
    ];
    const stringError = this.validateField(arrValidateFields);
    if (!super.isNullOrUndefined(stringError)) {
      this.showMessage(this.translate.instant('attention'), this.translate.instant('required') + `<br><br> ${stringError}`);
      return;
    }

    // EXPECTATION
    const expectations = this.modelExpectations.filter(x => x.isSelect);
    const expectationsEmpty = expectations.filter((x) => this.isNullOrUndefined(x.expectationDescription));
    if (!this.isNullOrUndefined(expectationsEmpty)) {
      const arrValidateFieldsExpectation = [];
      expectationsEmpty.map((x) => {
        arrValidateFieldsExpectation.push({
          value: x.expectationDescription,
          text: this.translate.instant(x.expectation) + ' <br />'
        });
      });
      const stringErrorExpectation = this.validateField(arrValidateFieldsExpectation);
      if (!super.isNullOrUndefined(stringErrorExpectation)) {
        this.showMessage(this.translate.instant('attention'), this.translate.instant('required') + `<br><br> ${stringErrorExpectation}`);
        return;
      }
    }
    if (!this.isNullOrUndefined(expectations)) {
      this.model.expectation = [];
      expectations.map((x) => {
        const a: IdeaExpectation = new IdeaExpectation();
        a.expectation = x.expectation;
        a.expectationDescription = x.expectationDescription;
        a.challengeLanguage = this.translate.currentLang === 'es' ? 'es-ES' : 'pt-BR';
        this.model.expectation.push(a);
      });
    }

    // DECLARA AUTHORS UMA LISTA VAZIA ANTES DE IR PARA O STEP
    this.model.authors = [];
    this.onNextModal('author');
  }

  // STEP 3
  onStepSaveThree() {
    const arrValidateFields = [
      {value: this.model.authorName, text: this.translate.instant('author.name') + ', <br />'},
      {value: this.model.document, text: 'Cargo, <br />'},
      {value: this.model.re, text: 'RE, <br />'},
      {value: this.model.turn, text: this.translate.instant('author.period') + ', <br />'},
      {value: this.model.managerName, text: this.translate.instant('author.superior') + ', <br />'},
      {value: this.model.phone, text: this.translate.instant('author.phone') + ', <br />'},
      {value: this.model.phoneExtensionLine, text: this.translate.instant('author.cell') + ', <br />'},
      {value: this.model.costCenter, text: this.translate.instant('author.costCenter') + ', <br />'},
      {value: this.model.contactHour, text: this.translate.instant('author.bestTime') + '<br />'}
    ];
    const stringError = this.validateField(arrValidateFields);
    if (!super.isNullOrUndefined(stringError)) {
      this.showMessage(this.translate.instant('attention'), this.translate.instant('required') + `<br><br> ${stringError}`);
      return;
    }

    this.onNextModal('unity');
  }

  onAddAuthor() {
    if (!this.isEMailValid(this.modelAuthorDetail.email)) {
      return;
    }
    if (!this.model.authors) {
      this.model.authors = [];
    }
    const e = this.model.authors?.filter((x) => x === this.modelAuthorDetail.name);
    if (e.length === 0) {
      this.model.authors.push({
        name: this.modelAuthorDetail.name,
        re: this.modelAuthorDetail.re,
        cpf: this.modelAuthorDetail.cpf,
        costCenter: this.modelAuthorDetail.costCenter,
        jobRole: this.modelAuthorDetail.jobRole,
        area: this.modelAuthorDetail.area,
        email: this.modelAuthorDetail.email,
      });
      this.modelAuthorDetail = {};
    }
  }

  onRemoveAuthor(i: number) {
    this.confirmMessage(this.translate.instant('attention'), 'Deseja realmente remover o author?', () => {
      this.model.authors.splice(i, 1);
    });
  }

  // STEP 4
  onGetUnityByCountry(i: any) {
    this.selectedCountry = i;
    this.countriesUnity = this.getUnitiesByCountryGQL.watch({id: i.id}).valueChanges.pipe(map(result => result.data.getUnitiesByCountry));
    this.model.unity = null;
    const countryIf: CreateIdeaCountryInput = {id: i.id};
    this.model.country = countryIf;
  }

  onChangeUnity($event: Event) {
    this.model.unity = {id: $($event.srcElement).val()};
  }

  onStepSaveFour() {
    if (this.isNullOrUndefined(this.model.unity)) {
      this.showMessage(this.translate.instant('attention'), this.translate.instant('selectcountry'));
      return;
    }
    this.onNextModal('productiveEquipment');
  }

  // STEP 5
  onStepSaveFive() {
    if (this.isNullOrUndefined(this.model.productiveEquipament)) {
      this.showMessage(this.translate.instant('attention'), this.translate.instant('selectoption'));
      return;
    }
    if (this.model.productiveEquipament) {
      if (this.isNullOrUndefined(this.model.productiveDescription)) {
        this.showMessage(this.translate.instant('attention'), this.translate.instant('selectproceed'));
        return;
      }
    }
    this.onNextModal('responsibleArea');
  }

  // STEP 6
  onStepSaveSix() {
    const arrValidateFields = [
      {value: this.model.areaResponsible, text: 'Área Principal, <br />'},
      {value: this.model.areaExtensionLine, text: 'Ramal, <br />'},
    ];
    const stringError = this.validateField(arrValidateFields);
    if (!super.isNullOrUndefined(stringError)) {
      this.showMessage(this.translate.instant('attention'), this.translate.instant('required') + `<br><br> ${stringError}`);
      return;
    }
    this.onNextModal('contact');
  }

  // STEP 7
  onStepSaveSeven() {
    const arrValidateFields = [
      {value: this.model.email, text: 'E-mail, <br />'},
      {value: this.modelEmailConfirm, text: 'Repita o e-mail, <br />'}
    ];
    const stringError = this.validateField(arrValidateFields);
    if (!super.isNullOrUndefined(stringError)) {
      this.showMessage(this.translate.instant('attention'), this.translate.instant('required') + `<br><br> ${stringError}`);
      return;
    }
    if (!this.isEMailValid(this.model.email)) {
      return;
    }
    if (this.model.email !== this.modelEmailConfirm) {
      super.showMessage(this.translate.instant('attention'), this.translate.instant('verify'));
      return;
    }
    $('#loaderBox').fadeIn();
    this.createIdeaGQL.mutate({data: this.model}).subscribe(
      () => {
        this.model = new CreateIdeaInput();
        this.modelAuthorDetail = {};
        this.modelEmailConfirm = null;
        this.modelExpectations = [
          {isSelect: false, expectation: 'finance', expectationDescription: ''},
          {isSelect: false, expectation: 'productive', expectationDescription: ''},
          {isSelect: false, expectation: 'sustainability', expectationDescription: ''}
        ];
        this.onNextModal('finish');
        this.eventbus.emit(new EmitEvent(Events.isUpdateIdea, true));
        $('#loaderBox').fadeOut();
      }, (err) => {
      }
    );
  }

  openModalAuthor(id: string) {
    if ((this.model.authors.length > 1)) {
      this.showMessage(this.translate.instant('attention'), this.translate.instant('twoAuthors'));
      $('#' + id).modal('hide');
      return;
    } else {
      $('#' + id).modal('show');
    }
  }

  private getAreas() {
    this.areasQuery = this.getAreasGQL.watch();
    this.areasQuery.valueChanges.subscribe(({data}) => {
      this.areas = data.areas as Array<Area>;
    });
  }
}
