<div class="clic-news">
  <header class="clic-news__head hero-bar">
    <div class="container">
      <div class="row">
        <div
          class="col-12 col-md-6 d-flex align-items-center justify-content-center justify-content-md-start mb-3 mb-md-0">
          <div class="clic-news__logo">
            <svg-icon class="svg" src="assets/images/logo-yellow2.svg"></svg-icon>
            <h2 class="clic-news__title"><strong>Clic</strong> NEWS</h2>
          </div>
        </div>
        <div class="col-12 col-md-6 d-flex align-items-center justify-content-center justify-content-md-end">
          <form action="#" class="search">
            <svg-icon class="svg" src="assets/images/search-small.svg"></svg-icon>
            <input type="text" #search (keyup)="onKey(search.value)" class="search__field"
                   placeholder="{{'search' | translate}}">
          </form>
        </div>
      </div>
    </div>
  </header>
  <section class="clic-news__body">
    <div class="container">
      <p class="d-flex justify-content-center py-5"
         *ngIf="isNullOrUndefined(news.news)">{{'noNewsFound' | translate}}</p>
      <a href="javascript:void('');" [routerLink]="'/clic-news-detail/' + n?.id" class="post" *ngFor="let n of news.news|
                paginate: {itemsPerPage: news.pageSize,
                currentPage: news.currentPage,
                totalItems: news.totalCount}">
        <div class="clic-news__body--thumb">
          <img [src]="n?.imageUrl" alt="{{n?.title}}"/>
        </div>
        <div>
          <h4 class="post__title post__title--height">{{n?.title}}</h4>
          <p class="post__text">{{n?.description}}</p>
          <div class="post__actions">
            <i class="post__date author">{{"by" | translate}} Eurofarma, {{n?.createdAt | date: 'dd/MM/yyyy'}}</i>
            <ul class="post__tags">
              <li *ngFor="let t of n?.tags"><a href="javascript:void('');">{{t?.tag}}</a></li>
            </ul>
          </div>
        </div>
      </a>
      <div>
        <pagination-controls
          [previousLabel]="labels.previousLabel"
          [nextLabel]="labels.nextLabel"
          [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
          [screenReaderPageLabel]="labels.screenReaderPageLabel"
          [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
          (pageChange)="pageChanged($event,search.value)"></pagination-controls>
      </div>
    </div>
  </section>
</div>
