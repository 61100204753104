import {AfterViewInit, Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {UserService} from '../../service/user.service';
import {TranslateService} from '@ngx-translate/core';

import {Apollo, gql, QueryRef} from 'apollo-angular';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {
  Query,
  GetUsersQuery,
  GetUsersGQL,
  CreateUserGQL,
  GetCountriesGQL, GetUnitiesByCountryGQL, User, MeGQL,
} from '../../../generated/graphql';


import * as moment from 'moment';

declare var $: any;


declare var $: any;

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent extends BaseComponent implements OnInit, AfterViewInit {
  title = 'web';

  users: Observable<Query['getUsers']>;
  usersQuery: QueryRef<GetUsersQuery>;
  modelUser: any = {};

  constructor(public router: Router,
              public translate: TranslateService,
              private apollo: Apollo,
              private getCountriesGQL: GetCountriesGQL,
              public meGQL: MeGQL,
              private getUnitiesByCountryGQL: GetUnitiesByCountryGQL,
              private getUsersGQL: GetUsersGQL,
              private createUserGQL: CreateUserGQL,
              public userService: UserService) {
    super(router, translate)
  }

  ngOnInit(): void {
     if (localStorage.getItem('culture') === 'pt-BR') {
      this.switchLanguage('pt');
    }
    if (localStorage.getItem('culture') === 'en-US') {
      this.switchLanguage('en');
    }
    if (localStorage.getItem('culture') === 'es-ES') {
      this.switchLanguage('es');
    }
    if (this.isNullOrUndefined(localStorage.getItem('culture'))) {
      super.switchLanguage('pt');
    }

    this.usersQuery = this.getUsersGQL.watch();
    this.users = this.usersQuery.valueChanges.pipe(map(result => result.data.getUsers));

    if (this.userService.getStatusAuthenticated()) {
      this.getCurrentUser();
    }
    // else {
    //   localStorage.clear();
    //   this.userService.isAuthenticated = false;
    //   window.location.href = '/logout';
    // }

    setTimeout(() => {
      super.toggleLoader(false);
    }, 1000);


  }

  ngAfterViewInit(): void {
    $(document).on('show.bs.modal', '.modal', function () {
      $(this).appendTo('body');
      const zIndex = 1040 + (10 * $('.modal:visible').length);
      $(this).css('z-index', zIndex);
      setTimeout(() => {
        $('.modal-backdrop').not('.modal-stack').css('z-index', zIndex - 1).addClass('modal-stack');
      }, 0);
    });
  }

  onCreateUser() {
    if (
      this.isNullOrUndefined(this.modelUser.email)
      || this.isNullOrUndefined(this.modelUser.password)
      || this.isNullOrUndefined(this.modelUser.role)
    ) {
      this.showMessage(this.translate.instant('attention'), this.translate.instant('fields'));
      return;
    }

    this.createUserGQL.mutate({
      input: {
        name: this.modelUser.name,
        email: this.modelUser.email,
        role: this.modelUser.role
      }
    }).subscribe(
      () => {
        this.modelUser = {};
        this.usersQuery.refetch();
      }, (err) => {
      }
    );

  }

  getCurrentUser() {
    this.meGQL.watch().valueChanges.subscribe(({data}) => {
      this.userService.updateUser(data.me as User);
    });
  }
}
