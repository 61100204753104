<div class="idea-detail">
  <header class="clic-news__head hero-bar details">
    <div class="container">
      <a href="javascript:void(0)" routerLink="/" class="clic-news__back">
        <svg-icon class="svg" src="assets/images/back.svg"></svg-icon>
        <span>{{"back" | translate}}</span>
      </a>
    </div>
  </header>
  <div class="idea-detail_challenge">
    <div class="container">
      <div class="challenge ">
        <div class="challenge_col-primary">
          <!--          comentamos porque não pode aparecer todos e a descrição estava lorem ipsum-->
          <!--          ideias antigas não tem desafio então o removemos o texto do desafio todos pra não aparecer-->
          <!--          pedido do cliente-->
<!--          <h1 *ngIf="!isNullOrUndefined(idea?.challenge?.name)"-->
<!--              class="challenge_text-name name-challenge">{{idea?.challenge?.name}}<br>-->
<!--            <span>{{idea?.challenge?.description}}</span></h1>-->
          <p>{{idea?.code}}</p>
          <h2 class="challenge_text-title">
            {{idea?.title}}
          </h2>
          <p class="challenge_text">
            {{idea?.description}}
          </p>
          <h2 *ngIf="!isNullOrUndefined(idea?.expectations)" class="challenge_text-title">
            {{"ideaDetail.return" | translate}}
          </h2>
          <ng-container *ngFor="let x of idea?.expectations">
            <h3 class="challenge_text-name name-secondary">
              {{getReturnNameByStr(x?.expectation)}}
            </h3>
            <p class="challenge_text">
              {{x?.expectationDescription}}
            </p>
            <hr/>
          </ng-container>

          <div class="d-flex flex-grow-1 mb-3 justify-content-end align-items-center"
               style="gap: 20px">
            <div (click)="actionLike()" class="d-flex align-items-center" style="gap: 10px">
              <span class="challenge_text text-right font-italic"
                    style="max-width: 150px">{{translate.instant('ideaPage.liked_ide')}}</span>
              <svg-icon src="assets/images/thumb_up.svg" class="svg"></svg-icon>
              <span class="challenge_text font-italic">{{idea?.likes}}</span>
            </div>
            <div class="d-flex align-items-center" style="gap: 10px">
              <svg-icon src="assets/images/comments.svg" class="svg"></svg-icon>
              <span class="challenge_text font-italic">{{idea?.comments?.length}}</span>
            </div>
          </div>
        </div>
        <div class="challenge_col-secondary">
          <div>
            <div class="d-flex justify-content-between" style="gap: 15px">
              <div class="mb-4">
                <div class="challenge_text text-value">{{"ideaDetail.registerDate" | translate}}</div>
                <div class="challenge_text text-date">{{idea?.createdAt | date:'dd/MM/yyyy'}}</div>
              </div>
              <span [attr.class]="'post__status btn no-events ' + getStatusClassByStr(idea?.status)"
                    style="height: fit-content;">
                <svg-icon [src]="getStatusIconByStr(idea?.status)" class="svg"></svg-icon>
                <span>{{getStatusNameByStr(idea?.status)}}</span>
              </span>
            </div>
            <div class="challenge_divider d-block" style="gap: 15px" *ngIf="idea?.status === 'denied'">
              <p class="text-right">{{"ideaDetail.motive" | translate}}</p>
              <p class="text-righ" [ngClass]="{'text-danger': idea?.status === 'denied'}">{{idea?.motive}}</p>
            </div>
            <div class="challenge_divider">&nbsp;</div>
          </div>
          <div>
            <h2 class="challenge_text-title">
              {{"ideaDetail.implement" | translate}}
            </h2>
            <div class="d-flex flex-column flex-sm-row">
              <div class="flex-grow-1">
                <dl>
                  <dt class="challenge_text-name name-lbl">
                    {{"ideaDetail.local" | translate}}
                  </dt>
                  <dd class="challenge_text text-value">
                    {{idea?.unity?.name}}
                  </dd>
                </dl>
                <dl>
                  <dt class="challenge_text-name name-lbl">
                    {{"ideaDetail.ramal" | translate}} | {{"ideaDetail.cellphone" | translate}}
                  </dt>
                  <dd class="challenge_text text-value">
                    {{idea?.phoneExtensionLine}} | {{idea?.phone}}
                  </dd>
                </dl>
              </div>
              <div class="flex-grow-1">
                <dl>
                  <dt class="challenge_text-name name-lbl">
                    {{"ideaDetail.equip" | translate}}
                  </dt>
                  <dd class="challenge_text text-value">
                    {{idea?.productiveDescription}}
                  </dd>
                </dl>
                <dl>
                  <dt class="challenge_text-name name-lbl">
                    {{"author.authorWord" | translate}}
                  </dt>
                  <dd class="challenge_text text-value">
                    {{idea.authorName}}
                  </dd>
                  <dd *ngFor="let a of idea.authors" class="challenge_text text-value">
                    {{a.name}}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="bottom my-5">
    <div class="container">
      <h4 class="form__label"><b>{{"ideaDetail.comments" | translate}}</b></h4>
      <div class="row">
        <div class="col-md-5 col-12">
          <p>{{"ideaDetail.commentIdea" | translate}}</p>
          <form>
            <div class="input-material">
              <input id="title" name="email" type="text"
                     [(ngModel)]="model.name" required class="form-control">
              <label for="title">{{"ideaDetail.name" | translate}}</label>
            </div>

            <div class="input-material mb-5">
              <textarea class="form-control" name="message" placeholder=""
                        [(ngModel)]="model.message" id="descriptionIdea" rows="5"></textarea>
              <label for="descriptionIdea">{{"ideaDetail.message" | translate}}</label>
            </div>


            <button type="button" class="btn-more effect w-100" (click)="onSaveIdeaComment()">
              <span>{{"ideaDetail.submit" | translate}}</span>
            </button>
          </form>

        </div>
        <div class="col-12 col-md-5 offset-md-1">
          <div class="my-2" *ngFor="let c of idea?.comments">
            <div class="d-flex justify-content-between">
              <div class="comment_title">
                <svg-icon src="assets/images/userblue.svg"></svg-icon>
                <b class="ml-3"> {{c?.name}}</b>
              </div>
              <div class="comment_data">{{c?.createdAt | date: 'dd/MM/yyyy'}}</div>
            </div>
            <p class="mt-2">{{c?.message}}</p>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
