<footer class="footer">
  <div class="container">
    <ul class="footer__links">
      <li><a href="javascript:void('');" (click)="onNavigateRouter('/');">{{'navTab.theClick' | translate}}</a></li>
      <li><a href="javascript:void('');" (click)="onNavScroll('numeros')">{{'navTab.numbers' | translate}}</a></li>
      <!--  Cliente solicitou ocultar o botão ‘insira sua idéia’ nesse primeiro momento da entrada em produção 15/12/2021-->
      <li *ngIf="false"><a href="javascript:void('');" routerLink="/register-idea">{{'navTab.subscribeIdea' | translate}}</a></li>
      <li><a href="{{this.clickPoliciesUrl}}" target="_blank">{{'navTab.clickPolicies' | translate}}</a></li>
      <li><a href="javascript:void('');" (click)="onNavigateRouter('/clic-news');">{{'navTab.news' | translate}}</a></li>
    </ul>
  </div>
  <div class="footer__dark">
    <div class="container">
      <p>{{"by" | translate}} Eurofarma, 21/05/21</p>
      <img src="assets/images/eurofarma.svg" alt="Eurofarma">
    </div>
  </div>
</footer>
