<ul class="header__menu" id="header_menu">
  <li class="d-flex d-xl-none">
    <a class="header__user">
      <span>{{'headerSalutation' | translate}}, <strong>{{user.name}}</strong></span>
      <img
        src="{{!user?.photo ? 'https://medicina.ucpel.edu.br/wp-content/uploads/2020/07/apacucpel_ucpel_image_319-1024x960.jpeg' : user?.photo}}"
        class="profile_photo"/>
    </a>
    <div class="header__btn">
      <a href="javascript:void('sair');" [routerLink]="['/logout']" class="header__user__outbtn">
        <strong class="header__user__outbtn__text">{{'sair' | translate}}</strong>
      </a>
    </div>
  </li>
  <li><a href="javascript:void('');" (click)="onNavScroll('programa')">{{'navTab.theClick' | translate}}</a></li>
  <li><a href="javascript:void('')" routerLink="/register-idea">{{'navTab.subscribeIdea' | translate}}</a></li>
  <li><a href="javascript:void('')" (click)="onNavScroll('ideias')">{{'consultIdea' | translate}}</a></li>
  <li><a href="javascript:void('')" (click)="onNavScroll('numeros')">{{'navTab.numbers' | translate}}</a></li>
  <li><a href="javascript:void('')" (click)="onNavScroll('')"
         [routerLink]="'/clic-news'">{{'navTab.news' | translate}}</a></li>
</ul>
