<header class="header">
  <div id="hamburger" (click)="menu()">
    <svg width="80" height="80" viewBox="0 0 100 100">
      <path class="line line-1"
            d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"/>
      <path class="line line-2" d="M 20,50 H 80"/>
      <path class="line line-3"
            d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"/>
    </svg>
  </div>
  <app-languages></app-languages>
  <app-menu></app-menu>
  <svg-icon (click)="onNavigateRouter('/')" class="svg logo" src="assets/images/logo.svg"></svg-icon>

  <div class="d-none d-xl-flex">
    <a href="#" class="d-none d-lg-flex align-items-center header__user"  style="gap: 10px">
      <span>{{'headerSalutation' | translate}}, <strong>{{user.name}}</strong></span>
      <img src="{{!user?.photo ? 'https://medicina.ucpel.edu.br/wp-content/uploads/2020/07/apacucpel_ucpel_image_319-1024x960.jpeg' : user?.photo}}"
           class="profile_photo"/>
    </a>
    <div class="header__btn">
      <a href="javascript:void('sair');" (click)="actionLogout()"  class="d-none d-lg-flex header__user__outbtn">
        <strong class="header__user__outbtn__text">{{'sair' | translate}}</strong>
      </a>
    </div>
  </div>
</header>

<div id="header_secondary" class="header__secondary open" [ngClass]="{ 'open': opened }">
  <svg-icon (click)="menu()" class="svg header__close" src="assets/images/close.svg"></svg-icon>
  <app-menu></app-menu>
  <app-languages></app-languages>
  <div class="header__secondary__footer">
    <img src="assets/images/eurofarma.svg" alt="Eurofarma">
  </div>
</div>
<div id="overlay" class="overlay open" [ngClass]="{ 'open': opened }"></div>
