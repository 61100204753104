import { Component, OnInit } from '@angular/core';
import {BaseComponent} from "../../base/base.component";
import {AuthType, LoginGQL} from "../../../generated/graphql";
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from "@ngx-translate/core";
import {UserService} from "../../service/user.service";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-authenticate',
  templateUrl: './authenticate.component.html',
  styleUrls: ['./authenticate.component.scss']
})
export class AuthenticateComponent extends BaseComponent implements OnInit {
  token = '';
  error = '';
  model: any = {code: '', application:'', email: '', redirectUri: window.location.protocol + '//' + window.location.host + window.location.pathname};
  modelEmailMock = '';
  // redirectUri: window.location.protocol + '//' + window.location.host + window.location.pathname

  constructor(public router: Router,
              public translate: TranslateService,
              public service: UserService,
              public login: LoginGQL,
              public route: ActivatedRoute) {
    super(router, translate);
  }

  ngOnInit(): void {
    /*this.model = {
        email: 'developers@duka.digital',
        password: '1qaz2wsx',
    };*/

    this.toggleLoader(false);

    // if (environment.isLoginMock) {
    if (environment.isLoginMock) {
      this.showModal('modalLoginEmail');
      return;
    }

    this.route.queryParams.subscribe(data => {
      if (data.error != null) {
        this.error = data.error_description;
        return;
      }
      if (data.code != null) {
        this.model.code = data.code;
        this.onLogin();
        return;
      }
      this.redirectAuth();
    });
  }

  redirectAuth() {
    const clientId = environment.client_id;
    const tenantId = environment.tenant_id;
    const p = window.location.pathname.split('/');
    const virtualPath = p.length >= 3 ? '/' + p[1] : '';
    const redirectUrl = window.location.origin + virtualPath + '/authenticate';
    const authUrl = 'https://login.microsoftonline.com/' + tenantId + '/oauth2/v2.0/authorize?client_id=' + clientId + '&response_type=code&redirect_uri=' + redirectUrl + '&response_mode=query&scope=openid%20offline_access%20https://graph.microsoft.com/mail.read&state=12345';
    window.location.href = authUrl;
  }


  onLogin() {
    this.model.application = 'web';
    this.login.mutate(this.model).subscribe(
      (data) => {
        const a: any = data;
        this.saveLocalUser(a.data.login);
      }, (err) => {
          super.onError(err);
      }
    );
  }

  saveLocalUser(data) {
    const token = data as AuthType;
    if (token.country === 'BR' || token.country === '') {
      localStorage.setItem('culture', 'pt-BR');
    }else {
      localStorage.setItem('culture', 'es-ES');
    }

    this.setToken(token.token);
    // this.service.updateUser(token.user);
    this.service.getStatusAuthenticated();
    this.router.navigate(['/']).then();
  }

  onLoginMock() {
    const arrValidateFields = [
      {value: this.modelEmailMock, text: `${this.translate.instant('email')}<br>`}
    ];
    const stringError = this.validateField(arrValidateFields);
    if (!super.isNullOrUndefined(stringError)) {
      this.showMessage(this.translate.instant('baseComponent.warningAttention'), `${this.translate.instant('baseComponent.warningCorrectFields')} <br> ${stringError}`);
      return;
    }
    this.closeModal('modalLoginEmail');
    this.model.email = this.modelEmailMock;
    this.onLogin();
  }

  closeLoginMock() {
    this.closeModal('modalLoginEmail');
    this.router.navigate(['/logout']);
  }
}
