import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import Swal from 'sweetalert2';
import * as moment from 'moment';

declare var $: any;

export class BaseComponent {
  isHeaderOpen = false;

  constructor(public router: Router,
              public translate: TranslateService) {
  }


  clickPoliciesUrl = 'https://eurofarmalaboratorio.sharepoint.com/sites/Conecta/Documentos Compartilhados/politicas/Diretoria Executiva de Empreendedorismo & Digital/EUR.ED.01 - Politica Programa CLIC.pdf?isSPOFile=1';

  setCulture(culture: string) {
    localStorage.setItem('culture', culture);
  }

  onNavScroll(str: string) {
    $('#header_menu').removeClass('open');
    $('#overlay').removeClass('open');
    $('#header_secondary').removeClass('open');
    if (this.isHeaderOpen && $(window).width() < 998) {
      this.toggleHeader();
    }
    if ($('#' + str)[0]) {
      $('html, body').animate({scrollTop: $('#' + str).offset().top}, 1000);
    } else {
      this.router.navigate(['/']);
      setTimeout(() => {
        $('html, body').animate({scrollTop: $('#' + str).offset().top}, 1000);
      }, 500);
    }
  }

  setToken(token: string) {
    localStorage.setItem('token', token);
  }

  showModal(idModal) {
    $('#' + idModal).modal('show');
  }

  closeModal(idModal) {
    $('#' + idModal).modal('hide');
  }

  toggleHeader() {
    this.isHeaderOpen = !this.isHeaderOpen;
  }

  switchLanguage(language: string, canReload = false) {
    if (language === 'pt') {
      language = 'pt';
      this.setCulture('pt-BR');
    } else if (language === 'en') {
      language = 'en';
      this.setCulture('en-US');
    } else if (language === 'es') {
      language = 'es';
      this.setCulture('es-ES');
    }
    this.translate.use(language);
    if (canReload) {
      location.reload();
    }
  }

  showMessage(t: string, m: string, type: any = 'warning') {
    Swal.fire({
      title: t,
      html: m,
      icon: type,
      confirmButtonColor: '#265ba3',
    });
  }

  onError(error) {
    if (error.status === 401) {
      this.router.navigate(['/authenticate']);
      return;
    }

    // const e = JSON.parse(error['error']);

    if (error.status === 400) {
      this.showMessage(this.translate.instant('baseComponent.warningAttention'), error.error.message, 'warning');
      return;
    }

    if (error.status === 500) {
      this.showMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('baseComponent.warningMsgError'), 'warning');
      return;
    }
  }

  confirmMessage(t: string, m: string, callback: any = null) {
    Swal.fire({
      title: t,
      html: m,
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      confirmButtonColor: '#265ba3',
      cancelButtonColor: '#dc3545'
    }).then((result) => {
      if (!this.isNullOrUndefined(result.value) && result.value === true) {
        if (callback != null) {
          callback();
        }
      }
    });
  }

  isNullOrUndefined(value, object = false) {
    if (object) {
      return typeof value === 'undefined' || value == null;
    }
    return typeof value === 'undefined' || value == null || value.length === 0;
  }

  validateField(obj) {
    let strError = ``;
    obj.map((e) => {
      if (this.isNullOrUndefined(e.value)) {
        strError += `${e.text}`;
      }
    });
    return strError;
  }

  isEMailValid(strEmail) {
    const str = strEmail;
    const specialChars = /[`!#$%^&*()+=\[\]{};':"\\|,<>\/?~]/;
    const exp = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i;

    const testSpecialChars = specialChars.test(str);
    if (testSpecialChars) {
      this.showMessage(this.translate.instant('attention'), this.translate.instant('emailInform'), 'warning');
      return !testSpecialChars;
    }

    const testResult = exp.test(str);
    if (!testResult) {
      this.showMessage(this.translate.instant('attention'), this.translate.instant('emailInform'), 'warning');
    }
    return testResult;
  }

  isCPFValid(strCPF) {
    let Soma;
    let Resto;
    Soma = 0;
    const strErrorTitle = this.translate.instant('attention');
    const strErrorDescription = 'Informe um CPF válido para prosseguir.';
    strCPF = strCPF.replace(/\D+/g, '');
    if (strCPF === '00000000000' || strCPF === '12112112155') {
      this.showMessage(strErrorTitle, strErrorDescription, 'warning');
      return false;
    }
    for (let i = 1; i <= 9; i++) {
      // tslint:disable-next-line:radix
      Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    }
    Resto = (Soma * 10) % 11;
    if ((Resto === 10) || (Resto === 11)) {
      Resto = 0;
    }
    // tslint:disable-next-line:radix
    if (Resto !== parseInt(strCPF.substring(9, 10))) {
      this.showMessage(strErrorTitle, strErrorDescription, 'warning');
      return false;
    }
    Soma = 0;
    for (let i = 1; i <= 10; i++) {
      // tslint:disable-next-line:radix
      Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    }
    Resto = (Soma * 10) % 11;
    if ((Resto === 10) || (Resto === 11)) {
      Resto = 0;
    }
    // tslint:disable-next-line:radix
    if (Resto !== parseInt(strCPF.substring(10, 11))) {
      this.showMessage(strErrorTitle, strErrorDescription, 'warning');
      return false;
    }
    return true;
  }

  dateOfBirthDateValidate(p: any) {
    // p = (p instanceof Date) ? p : moment(p, 'DD/MM/yyyy').format('DD/MM/yyyy');
    console.log('dateOfBirthDateValidate');
    console.log(p);
    if (p.indexOf('/') >= 0 || p.indexOf('-') >= 0) {
      p = moment(p, 'DD/MM/yyyy').format('DD/MM/yyyy');
    } else if (!this.isNullOrUndefined(p) && p.indexOf('/') < 0) {
      p = moment(p).format('DD/MM/yyyy');
    }
    const dateFormatMin = moment().subtract(18, 'year');
    const dateFormatMax = moment().subtract(120, 'year');
    const dateFormatCurrent = moment();
    let valReturn = true;
    if (this.isNullOrUndefined(p) || p === 'Invalid date') {
      this.showMessage(this.translate.instant('attention'), this.translate.instant('birthdate'), 'warning');
      valReturn = false;
    } else if (!moment(p, 'DD/MM/YYYY', true).isValid()) {
      this.showMessage(this.translate.instant('attention'), this.translate.instant('birthdate'), 'warning');
      valReturn = false;
    } else if (moment(p, 'DD/MM/YYYY') > dateFormatCurrent) {
      this.showMessage(this.translate.instant('attention'), this.translate.instant('birthdate'), 'warning');
      valReturn = false;
    } else if (moment(p, 'DD/MM/YYYY') > dateFormatMin) {
      this.showMessage(this.translate.instant('attention'), this.translate.instant('uEighteen'), 'warning');
      valReturn = false;
    } else if (moment(p, 'DD/MM/YYYY') < dateFormatMax) {
      this.showMessage(this.translate.instant('attention'), this.translate.instant('aThousand'), 'warning');
      valReturn = false;
    }
    return valReturn;
  }

  toggleLoader(b: boolean): void {
    if (!b) {
      $('#loaderBox').fadeOut();
    } else {
      $('#loaderBox').fadeIn();
    }
  }


  getStatusNameByStr(status: string) {
    if (this.isNullOrUndefined(status)) {
      return;
    }
    return this.translate.instant(status);
  }

  getStatusClassByStr(status: string) {
    let c = '';
    switch (status) {
      case 'approved':
        c = 'btn-success';
        break;
      case 'denied':
        c = 'btn-danger';
        break;
      case 'awarded':
        c = 'btn-info';
        break;
      case 'analysis':
        c = 'btn-secondary';
        break;
      case 'denial':
        c = 'btn-secondary';
        break;
      default:
        c = 'btn-light';
        break;
    }
    return c;
  }

  getStatusIconByStr(status: string) {
    if (this.isNullOrUndefined(status)) {
      return;
    }
    return 'assets/images/' + status + '.svg';
  }

  getReturnNameByStr(status: string) {
    if (this.isNullOrUndefined(status)) {
      return;
    }
    return this.translate.instant(status);
  }

}
