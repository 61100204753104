import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainComponent} from './views/main/main.component';
import {HomeComponent} from './views/home/home.component';
import {TermsComponent} from './views/terms/terms.component';
import {ClicNewsComponent} from './views/clic-news/clic-news.component';
import {ClicNewsDetailComponent} from './views/clic-news-detail/clic-news-detail.component';
import {RegisterIdeaComponent} from './views/register-idea/register-idea.component';
import {IdeaDetailComponent} from './views/idea-detail/idea-detail.component';
import {environment} from '../environments/environment';
import {AuthenticateComponent} from "./views/authenticate/authenticate.component";
import {LogoutComponent} from "./views/logout/logout.component";
import {AuthGuardUser} from "./service/auth-guard-user.service";


export function handlerRouter() {
  const url = window.location.href.split('/', 3)[2];
  if (environment.base_directory === url) {
    return [
      {
        path: '', component: MainComponent,
        children: [
          {path: '', pathMatch: 'full', redirectTo: 'register'},
          {path: 'register', component: RegisterIdeaComponent},
        ],
      }
    ]


  }
  return [
    {path: 'authenticate', component: AuthenticateComponent},
    {path: 'logout', component: LogoutComponent},
    {
      path: '', component: MainComponent,
      canActivate: [AuthGuardUser],
      children: [
        {path: '', pathMatch: 'full', redirectTo: 'home'},
        {path: 'home', component: HomeComponent},
        {path: 'clic-news', component: ClicNewsComponent},
        {path: 'clic-news/:id', component: ClicNewsComponent},
        {path: 'clic-news-detail/:id', component: ClicNewsDetailComponent},
        {path: 'idea-detail/:id', component: IdeaDetailComponent},
        {path: 'idea-detail', component: IdeaDetailComponent},
        {path: 'register-idea', component: RegisterIdeaComponent},
        {path: 'register', component: RegisterIdeaComponent},
        {path: 'politica', component: TermsComponent},
      ]
    },
  ];
}

const routes: Routes = handlerRouter();

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    relativeLinkResolution: 'legacy',
    scrollOffset: [0, 0],
    scrollPositionRestoration: 'top'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
