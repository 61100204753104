<div class="clic-news">
  <header class="clic-news__head hero-bar details">
    <div class="container">
      <a href="javascript:history.back(-1);" class="clic-news__back">
        <svg-icon class="svg" src="assets/images/back.svg"></svg-icon>
        <span>{{'back' | translate}}</span>
      </a>
    </div>
  </header>
  <section class="clic-news__body">
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-5">
          <img class="clic-news__image" [src]="new?.imageUrl" alt="{{new?.title}}">
        </div>
        <div class="col-12 col-lg-6">
          <i class="post__date author">{{"by" | translate}} {{new?.author}}, {{new?.createdAt | date: 'dd/MM/yyyy'}}</i>
          <h2 class="post__title post__title--height clic-news__name">{{new?.title}}</h2>
          <p class="post__title post__title--height font-bold">{{new?.description}}</p>
            <div class="post__text post__text--height" [innerHTML]="new?.content"></div>
            <hr>
            <h5 class="post__tag-title">Tags</h5>
            <ul class="post__tags last">
              <li *ngFor="let t of new?.tags"><a href="javascript:void('');" [routerLink]="'/clic-news/' + t?.tag">{{t?.tag}}</a></li>
            </ul>
        </div>
      </div>
    </div>
  </section>
</div>
