import {BrowserModule} from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {MainComponent} from './views/main/main.component';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {NgxMaskModule} from 'ngx-mask';
import {CurrencyMaskModule} from 'ng2-currency-mask';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {SlickCarouselModule} from 'ngx-slick-carousel';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HomeComponent} from './views/home/home.component';
import {RegisterComponent} from './views/register/register.component';
import {LoginComponent} from './views/login/login.component';
import {TermsComponent} from './views/terms/terms.component';
import {RecaptchaModule} from 'angular-google-recaptcha';
import {HeaderComponent} from './views/header/header.component';
import {FooterComponent} from './views/footer/footer.component';
import {ClicNewsComponent} from './views/clic-news/clic-news.component';
import {ClicNewsDetailComponent} from './views/clic-news-detail/clic-news-detail.component';
import {RegisterIdeaComponent} from './views/register-idea/register-idea.component';
import {MenuComponent} from './views/menu/menu.component';
import {LanguagesComponent} from './views/languages/languages.component';
import {IdeaDetailComponent} from './views/idea-detail/idea-detail.component';
import {CountUpModule} from 'ngx-countup';
import {GraphQLModule} from './graphql.module';
import {OnlyNumberDirective} from './directive/only-number.directive';
import {EventBusService} from './service/event-bus.service';
import {NgxPaginationModule} from 'ngx-pagination';
import {HeaderHttpInterceptor} from './base/header-interceptor';
import {AuthenticateComponent} from "./views/authenticate/authenticate.component";
import {LogoutComponent} from "./views/logout/logout.component";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    HomeComponent,
    RegisterComponent,
    LoginComponent,
    TermsComponent,
    HeaderComponent,
    FooterComponent,
    ClicNewsComponent,
    ClicNewsDetailComponent,
    RegisterIdeaComponent,
    MenuComponent,
    OnlyNumberDirective,
    LanguagesComponent,
    IdeaDetailComponent,
    AuthenticateComponent,
    LogoutComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    CurrencyMaskModule,
    SlickCarouselModule,
    ReactiveFormsModule,
    CountUpModule,
    RecaptchaModule.forRoot({
      siteKey: '6Lf5xoEcAAAAAOmsoNCcR-lXOXHorPiV835x0cIg',
    }),
    AngularSvgIconModule.forRoot(),
    NgxMaskModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgxPaginationModule,
    BrowserAnimationsModule,
    GraphQLModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    EventBusService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeaderHttpInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
