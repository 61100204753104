<h1>Cadastro</h1>

<form action="#" autocomplete="off">
  <h2>Dados Pessoais</h2>
  <div class="row">
    <div class="form-group col-12 col-lg-3">
      <label for="promoCpf">CPF</label>
      <input name="promoCpf" id="promoCpf" type="text" class="form-control"
             [clearIfNotMatch]="true"
             (blur)="onClearModel($event, 'cpf')"
             [(ngModel)]="modelUser.cpf" [mask]="'000.000.000-00'" [dropSpecialCharacters]="false" />
    </div>
  </div>
  <div class="row">
    <div class="form-group col-12 col-lg-4">
      <label for="promoName">Nome</label>
      <input name="promoName" id="promoName" type="text" class="form-control" [(ngModel)]="modelUser.name" />
    </div>
    <div class="form-group col-12 col-lg-4">
      <label for="promoEmail">E-mail</label>
      <input name="promoEmail" id="promoEmail" type="text" class="form-control" [(ngModel)]="modelUser.email" />
    </div>
    <div class="form-group col-12 col-lg-4">
      <label for="promoEmailConfirm">Confirmar E-mail</label>
      <input name="promoEmailConfirm" id="promoEmailConfirm" type="text" class="form-control" [(ngModel)]="promoEmailConfirm" />
    </div>
    <div class="form-group col-12 col-lg-3 my-4">
      <label class="mr-3">Sexo</label>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="promoGender" id="promoGenderM"
               [checked]="modelUser.sex === 'm'"
               (change)="onChangeGender('m')"
        />
        <label class="form-check-label" for="promoGenderM">M</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="promoGender" id="promoGenderF"
               [checked]="modelUser.sex === 'f'"
               (change)="onChangeGender('f')"
        />
        <label class="form-check-label" for="promoGenderF">F</label>
      </div>
    </div>
    <div class="form-group col-12 col-lg-3">
      <label for="promoBirthdate">Data de nascimento</label>
      <input name="promoBirthdate" id="promoBirthdate" type="text" class="form-control"
             [clearIfNotMatch]="true"
             (blur)="onClearModel($event, 'birthdate')"
             [(ngModel)]="modelUser.birthdate" [mask]="'d0/M0/0000'" [dropSpecialCharacters]="false" />
    </div>
    <div class="form-group col-12 col-lg-3">
      <label for="promoPhone">{{'phone'|translate}}</label>
      <input name="promoPhone" id="promoPhone" type="text" class="form-control"
             [(ngModel)]="modelUser.phone" [mask]="'(00) 0000-0000||(00) 00000-0000'" [dropSpecialCharacters]="false"
             [clearIfNotMatch]="true"
             (blur)="onClearModel($event, 'phone')"
      />
    </div>
    <div class="form-group col-12 col-lg-3">
      <label for="promoCellphone">Celular</label>
      <input name="promoCellphone" id="promoCellphone" type="text" class="form-control"
             [(ngModel)]="modelUser.cellphone" [mask]="'(00) 0000-0000||(00) 00000-0000'" [dropSpecialCharacters]="false"
             [clearIfNotMatch]="true"
             (blur)="onClearModel($event, 'cellphone')"
      />
    </div>
  </div>

  <h2>Endereço</h2>
  <div class="row">
    <div class="form-group col-12 col-lg-3">
      <label for="promoZipcode">CEP</label>
      <input name="promoZipcode" id="promoZipcode" type="text" class="form-control"
             [(ngModel)]="modelUser.zipcode" [mask]="'00000-000'" [dropSpecialCharacters]="false"
             [clearIfNotMatch]="true"
             (blur)="onClearModel($event, 'zipcode')"
             (keyup)="onZipCodeAutoComplete($event)"
      />
    </div>
    <div class="form-group col-12 col-lg-6">
      <label for="promoAddress">Endereço</label>
      <input name="promoAddress" id="promoAddress" type="text" class="form-control" [(ngModel)]="modelUser.address" />
    </div>
    <div class="form-group col-12 col-lg-3">
      <label for="promoNumber">Nº</label>
      <input name="promoNumber" id="promoNumber" type="text" class="form-control" [(ngModel)]="modelUser.number" />
    </div>
    <div class="form-group col-12 col-lg-3">
      <label for="promoComplement">Complemento</label>
      <input name="promoComplement" id="promoComplement" type="text" class="form-control" [(ngModel)]="modelUser.complement" />
    </div>
    <div class="form-group col-12 col-lg-3">
      <label for="promoState">Estado</label>
      <input name="promoState" id="promoState" type="text" class="form-control" [(ngModel)]="modelUser.state_id" />
    </div>
    <div class="form-group col-12 col-lg-3">
      <label for="promoCity">Cidade</label>
      <input name="promoCity" id="promoCity" type="text" class="form-control" [(ngModel)]="modelUser.city_id" />
    </div>
    <div class="form-group col-12 col-lg-3">
      <label for="promoDistrict">Bairro</label>
      <input name="promoDistrict" id="promoDistrict" type="text" class="form-control" [(ngModel)]="modelUser.district" />
    </div>
  </div>

  <h2>Acessos</h2>
  <div class="row">
    <div class="form-group col-12 col-lg-5">
      <label for="promoPassword">Senha</label>
      <input name="promoPassword" id="promoPassword" type="password" class="form-control" [(ngModel)]="modelUser.password" />
    </div>
    <div class="form-group col-12 col-lg-4">
      <label for="promoPasswordConfirm">Confirmar senha</label>
      <input name="promoPasswordConfirm" id="promoPasswordConfirm" type="password" class="form-control" [(ngModel)]="modelUser.passwordConfirm" />
    </div>
  </div>


  <div class="form-group my-5">
    <recaptcha
      name="recaptcha"
      [(ngModel)]="myRecaptcha"
      (scriptLoad)="onScriptLoad()"
      (scriptError)="onScriptError()"
    ></recaptcha>
  </div>
  <div class="form-check">
    <input type="checkbox" class="form-check-input" id="promoAcceptTerms" name="promoAcceptTerms"
           [checked]="modelUser.is_accept"
           (change)="modelUser.is_accept = !modelUser.is_accept"
    />
    <label class="form-check-label" for="promoAcceptTerms">Li e aceito os termos do <a href="/regulamento" target="_blank">Regulamento</a></label>
  </div>
  <div class="form-check">
    <input type="checkbox" class="form-check-input" id="promoReceiveInfo" name="promoReceiveInfo"
           [checked]="modelUser.is_receive_info"
           (change)="modelUser.is_receive_info = !modelUser.is_receive_info"
    />
    <label class="form-check-label" for="promoReceiveInfo">Aceito receber informações sobre a campanha</label>
  </div>
  <div class="form-group mt-5">
    <a href="javascript:void('');" (click)="onSave()" class="btn btn-block btn-primary">Enviar</a>
  </div>
</form>
