import { Component, OnInit } from '@angular/core';
import {BaseComponent} from "../../base/base.component";
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";


declare var $: any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends BaseComponent implements OnInit {

  constructor(public router: Router,
              public translate: TranslateService,) {
    super(router, translate)
  }

  ngOnInit(): void {
  }

  onNavigateRouter(s: string) {
    // $('html, body').stop().animate({scrollTop: 0}, 1000);
    this.router.navigate([s]);
  }

  onNavScroll(str: string) {
    if ($('#' + str)[0]) {
      $('html, body').animate({scrollTop: $('#' + str).offset().top}, 1000);
    } else {
      this.router.navigate(['/']);
      setTimeout(() => {
        $('html, body').animate({scrollTop: $('#' + str).offset().top}, 1000);
      }, 1000);
    }
  }

}
