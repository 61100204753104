import { Component, OnInit } from '@angular/core';
import {BaseComponent} from "../../base/base.component";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-languages',
  templateUrl: './languages.component.html',
  styleUrls: ['./languages.component.scss']
})
export class LanguagesComponent extends BaseComponent implements OnInit {

  constructor(public router: Router,
              public translate: TranslateService,) {
    super(router, translate)
  }

  ngOnInit(): void {
  }

}
