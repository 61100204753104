import {AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

import {Apollo, gql, QueryRef} from 'apollo-angular';
import {Observable, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';

import {
  DashboardHome,
  GetAllIdeasGQL,
  GetAllIdeasQuery,
  GetCountriesGQL,
  GetDashboardHomeGQL,
  GetDashboardHomeQuery, GetDashboardHomeQueryVariables,
  GetIdeasSearchGQL,
  GetIdeasSearchQuery,
  GetNewsGQL,
  GetNewsQuery,
  GetUnitiesByCountryGQL, MeGQL, SearchIdeaInput, User,
} from '../../../generated/graphql';
import {EventBusService, Events} from '../../service/event-bus.service';
import {UserService} from "../../service/user.service";


declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

  eventbusSub: Subscription;
  filter: number;
  newIdea = 0;
  approvedIdeas = 0;
  awardeds: any[] = [];
  financialFeedback = 0;
  modelIdeaFilter: SearchIdeaInput = new SearchIdeaInput();
  modelAllIdeal: any = {keyword: ''};

  ideasQuery: QueryRef<GetIdeasSearchQuery>;
  ideas: any; //  users: Observable<Query['getUsers']>;
  countries: any;
  unities: any;

  allIdeasQuery: QueryRef<GetAllIdeasQuery>;
  allIdeas: any;
  isSearchClic = false;

  allNewsQuery: QueryRef<GetNewsQuery>;
  allNews: any;

  options = {
    decimalPlaces: 2,
    separator: '.',
    decimal: ',',
  };
  homeDashQuery: QueryRef<GetDashboardHomeQuery>;
  homeDash: DashboardHome = new DashboardHome();
  homeDashNumbers: DashboardHome = {
    news: 0,
    approves: 0,
    finances: 0,
    energy: 0,
    machineValue: 0,
    manValue: 0,
    waste: 0,
    water: 0
  };

  slideConfig = {
    slidesToShow: 2.3,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    infinite: false,
    // centerMode: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  slideConfigCentered = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    infinite: true,
    centerMode: true,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          // centerMode: false
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
          centerMode: false
        }
      }
    ]
  };

  @ViewChild('newIdeaCounte') newIdeaCounte: ElementRef;
  @ViewChild('approvedIdeasCounte') approvedIdeasCounte: ElementRef;
  @ViewChild('financialFeedbackCounte') financialFeedbackCounte: ElementRef;
  @ViewChild('manValueCounte') manValueCounte: ElementRef;
  @ViewChild('waterValueCounte') waterValueCounte: ElementRef;
  @ViewChild('wasteValueCounte') wasteValueCounte: ElementRef;
  @ViewChild('energyValueCounte') energyValueCounte: ElementRef;
  @ViewChild('machineValueCounte') machineValueCounte: ElementRef;
  @ViewChild('seekRecognition') seekRecognition: ElementRef;

  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event) {
    if (this.scrollCalculation(this.newIdeaCounte)) {
      this.homeDashNumbers.news = this.homeDash?.news;
    }
    if (this.scrollCalculation(this.approvedIdeasCounte)) {
      this.homeDashNumbers.approves = this.homeDash?.approves;
    }
    if (this.scrollCalculation(this.financialFeedbackCounte)) {
      this.homeDashNumbers.finances = this.homeDash?.finances;
    }
    if (this.scrollCalculation(this.manValueCounte)) {
      this.homeDashNumbers.manValue = this.homeDash?.manValue;
    }
    if (this.scrollCalculation(this.waterValueCounte)) {
      this.homeDashNumbers.water = this.homeDash?.water;
    }
    if (this.scrollCalculation(this.wasteValueCounte)) {
      this.homeDashNumbers.waste = this.homeDash?.waste;
    }
    if (this.scrollCalculation(this.energyValueCounte)) {
      this.homeDashNumbers.energy = this.homeDash?.energy;
    }
    if (this.scrollCalculation(this.machineValueCounte)) {
      this.homeDashNumbers.machineValue = this.homeDash?.machineValue;
    }
    if (this.scrollCalculation(this.seekRecognition)) {
      $('#seekRecognition').addClass('active');
    }
  }

  scrollCalculation(rects): boolean {
    const rect = rects.nativeElement.getBoundingClientRect(); // rects.nativeElement.getBoundingClientRect();
    const elemTop = rect.top;
    const elemBottom = rect.bottom;
    if (window.innerWidth < 980) {
      return true;
    } else {
      return (elemTop >= 0) && (elemBottom <= window.innerHeight);
    }
  }

  constructor(public router: Router,
              public translate: TranslateService,
              private eventbus: EventBusService,
              private apollo: Apollo,
              private getAllIdeasGQL: GetAllIdeasGQL,
              private getIdeasSearchGQL: GetIdeasSearchGQL,
              private getDashboardHomeGQL: GetDashboardHomeGQL,
              private getCountriesGQL: GetCountriesGQL,
              public meGQL: MeGQL,
              public userService: UserService,
              private getNewsGQL: GetNewsGQL,
              private getUnitiesByCountryGQL: GetUnitiesByCountryGQL,
  ) {
    super(router, translate);
  }

  ngOnInit(): void {

    this.eventbusSub = this.eventbus.on(Events.isUpdateIdea, ((data) => this.handlerUpdateIdeaList(data)));

    // estre trecho comentado leva em consideração a pesquisa de ideas de acordo com o idioma escolhido na página
    /*
    this.allIdeasQuery = this.getAllIdeasGQL.watch(this.modelAllIdeal);
    this.allIdeasQuery.valueChanges.subscribe(({data}) => {
      this.allIdeas = data.getAllIdeas
        .filter(a => !this.isNullOrUndefined(a.challengeLanguage) &&
          a.challengeLanguage.includes(this.translate.currentLang));
      console.log(this.allIdeas)

      setTimeout(() => {
        $(() => {
          $('#slick-slider-ideal').slick(this.slideConfig).css({opacity: 1, visibility: 'visible'});
        });
      }, 500);
    });

     */

    // cliente solicitou que a busca seja realizada independente do idioma escolhido na página
    // removemos o filter que verifica o idioma setado no local storage
    this.allIdeasQuery = this.getAllIdeasGQL.watch(this.modelAllIdeal);
    this.allIdeasQuery.valueChanges.subscribe(({data}) => {
      this.allIdeas = data.getAllIdeas;

      setTimeout(() => {
        $(() => {
          $('#slick-slider-ideal').slick(this.slideConfig).css({opacity: 1, visibility: 'visible'});
        });
      }, 500);
    });

    this.ideasQuery = this.getIdeasSearchGQL.watch({input: this.modelIdeaFilter});
    this.ideasQuery.valueChanges.subscribe(({data}) => {
      this.ideas = data.getIdeasSearch.filter(a => !this.isNullOrUndefined(a.challengeLanguage)
        && a.challengeLanguage.includes(this.translate.currentLang));
      setTimeout(() => {
        $(() => {
          $('#slick-slider-implement').slick(this.slideConfig);
        });
      }, 500);
    });

    this.allNewsQuery = this.getNewsGQL.watch();
    this.allNewsQuery.valueChanges.subscribe(({data}) => {
      this.allNews = data.getNews;
      setTimeout(() => {
        $(() => {
          $('#slick-slider-news').slick(this.slideConfigCentered);
        });
      }, 500);
    });

    this.getCountriesGQL.watch().valueChanges.subscribe(({data}) => {
      this.countries = data.getCountries;
    });

    this.homeDashQuery = this.getDashboardHomeGQL.watch();

    this.homeDashQuery.valueChanges.subscribe(({data}) => {
      this.homeDash = data.getDashboardHome as DashboardHome;
    });

  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
    this.eventbusSub.unsubscribe();
  }

  handlerUpdateIdeaList(d: any): void {
    this.allIdeasQuery.refetch(this.modelAllIdeal);
    this.actionFilterIdeaAll();
  }

  validateFilter() {
    return {
      keyword: this.modelIdeaFilter.keyword,
      unityId: this.modelIdeaFilter.unityId === undefined ? '' : this.modelIdeaFilter.unityId,
    };
  }


  actionFilterIdeaAll() {
    this.modelAllIdeal.keyword = this.filter.toString();
    if (this.isNullOrUndefined(this.modelAllIdeal.keyword)) {
      this.showMessage(this.translate.instant('attention'), this.translate.instant('ReRequireMsg'), 'warning');
      return;
    }
    this.isSearchClic = true;
    const el = $('#slick-slider-ideal');
    el.css({opacity: 0, visibility: 'hidden'}).slick('unslick');
    this.allIdeasQuery.refetch(this.modelAllIdeal);
  }

  actionFilterIdea() {
    const el = $('#slick-slider-implement');
    el.css({opacity: 0, visibility: 'hidden'}).slick('unslick');
    this.ideasQuery.refetch({input: this.modelIdeaFilter});
    setTimeout(() => {
      $(() => {
        $('#slick-slider-implement').slick(this.slideConfig).css({opacity: 1, visibility: 'visible'});
      });
    }, 500);
  }

  handlerCountry($event = '') {
    this.modelIdeaFilter.unityId = undefined;
    this.getUnitiesByCountryGQL.watch({id: $event}).valueChanges.subscribe(({data}) => {
      this.unities = data.getUnitiesByCountry;
    });
  }

  onNavigateRouter(s: string) {
    // $('html, body').stop().animate({scrollTop: 0}, 1000);
    this.router.navigate([s]);
  }

  onNavScroll(str: string) {
    $('html, body').animate({scrollTop: $('#' + str).offset().top}, 1000);
  }

  getAwardeds() {
    this.awardeds = this.ideas?.filter(a => a.status === 'awarded');
    return this.awardeds;
  }

}


