import {AfterViewInit, Component, OnInit, OnDestroy} from '@angular/core';
import {BaseComponent} from "../../base/base.component";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {UserService} from "../../service/user.service";
import {User} from "../../../generated/graphql";

declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

  opened = false;
  user: User;

  constructor(public router: Router,
              public translate: TranslateService,
              public userService: UserService) {
    super(router, translate)
  }

  menu() {
    this.opened = !this.opened;
  }

  ngOnInit(): void {
    this.userService.user.subscribe({
      next: data => {
        this.user = data as User;
      }, error: err => super.onError(err)
    });
  }

  ngAfterViewInit() {
    window.onscroll = () => {
      const hero = document.querySelector<HTMLElement>('.hero-bar');
      const header = document.querySelector<HTMLElement>('.header');

      window.pageYOffset >= hero.offsetHeight - 105 ? header.classList.add('dark') : header.classList.remove('dark');
    };
  }

  onNavigateRouter(s: string) {
    this.router.navigate([s]);
    // $('html, body').stop().animate({scrollTop: 0}, 1000);
  }

  ngOnDestroy(): void {
    // document.querySelector<HTMLElement>('.header').classList.remove('dark');
  }

  actionLogout() {
    localStorage.clear();
    const p = window.location.pathname.split('/');
    // const virtualPath = p.length >= 3 ? '/' + p[1] : '';
    const redirectUrl = window.location.origin +  '/logout';
    const logoutURL = 'https://login.microsoftonline.com/common/oauth2/logout?post_logout_redirect_uri=' + redirectUrl;
    window.location.href = logoutURL;
  }

}
